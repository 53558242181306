import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuardService {
  constructor(private auth: AuthService, private router: Router) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let AuthState: any = this.auth.isAuthenticated();
    const userFetched = await this.auth.userFetched();
    if (AuthState && userFetched.get('role') === 'admin') {
        // logged in so return true
        return true;
    }

    // not logged in so redirect to login page with the return url and return false
    this.router.navigateByUrl('/login');
    return false;
  }
}
