<!-- <mat-dialog-container> -->
    <p>Please Sign on the line below</p>
    <mat-dialog-content #container class="w-100 signPadContainer">
        <canvas class="signPad" #signPadCanvas (touchstart)="startSignPadDrawing($event)" (touchmove)="movedFinger($event)"></canvas>
    </mat-dialog-content>
    <hr class="mt-0">
    {{data.name}} {{data.surname}}
    <mat-dialog-actions class="d-flex justify-content-between">
        <div>
            <button mat-raised-button color="default" (click)="cancel()">Cancel</button>
            <button mat-button color="warn" (click)="clearSignPad()">Clear</button>
            <button mat-button (click)="undoSign()">Undo</button>
        </div>
        <div>
            <button  mat-raised-button color="primary" (click)="saveSignPad()">Save</button>
        </div>
    </mat-dialog-actions>
<!-- </mat-dialog-container> -->