import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CancelDialogComponent } from 'src/app/shared/cancel-dialog/cancel-dialog.component';
import { Location } from '../../../../backend/interfaces/location.interface';
import { LocationService } from 'src/app/admin/services/location.service';
import { StudentService } from 'src/app/admin/services/student.service';
import { DataService } from 'src/app/services/data.service';
import { AlbumDialogComponent } from 'src/app/shared/album-dialog/album-dialog.component';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
@Component({
  selector: 'app-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.scss']
})
export class ViewProjectComponent implements OnInit {
  loading = false;
  location: Location;
  faTimes  = faTimes;
  projectPhotos = [];
  albumId: string;
  albumTitle: string;
  coverPhoto: string;
  albumList = [];
  albumInfo;
  settings = {
    counter: false,
    plugins: [lgZoom],
  };
  constructor(private dataService: DataService, private studentService: StudentService, private locationService: LocationService, private router: Router, private ar: ActivatedRoute, public dialog: MatDialog) { }
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
};
  async ngOnInit(): Promise<void> {
    this.loading = true;
    
    await this.dataService.getAlbums('')
        this.dataService.albums$.subscribe(res => {
          if(res){
          this.albumList = res;
          }
        })
        this.albumId = this.ar.snapshot.params['id'];
        this.albumInfo = this.albumList.find(i => i.id === this.albumId);
        await this.studentService.getAlbumById(this.albumId).then(res => {
          this.projectPhotos = res.mediaItems;
          this.albumTitle = this.albumInfo.title;
          this.coverPhoto = 'url("'+this.albumInfo.coverPhotoBaseUrl+'")';
        });
        this.loading = false;


    
    // if(this.locationService.openLocation){
    //   this.projectPhotos = await this.studentService.getAlbumById(this.locationService.openLocation.id).then(res => res.mediaItems);
    //   this.location = this.locationService.openLocation;
    // }else{
    //   this.router.navigate(['projects']);
    // }
    
  }

  viewPhotos(){
    const dialogRef = this.dialog.open(AlbumDialogComponent, {
      data: {studentId: '', albumId: '', files: this.projectPhotos, type: 'photos' },
      width: '90%',
      maxWidth: '100vw',
      height: '90%',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(result);
      } else {
        this.dialog.closeAll();
      }
    });
  }

  
}
