import { Component, OnInit } from '@angular/core';
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-letterhead',
  templateUrl: './letterhead.component.html',
  styleUrls: ['./letterhead.component.scss']
})
export class LetterheadComponent implements OnInit {
  faPhone = faPhone;
  faHome = faHome;
  faEnvelope = faEnvelope;
  constructor() { }

  ngOnInit(): void {
  }

}
