<div class="d-flex">
  <img class="logo" [src]="data.icon"/>
  <h2 class="flex-1" mat-dialog-title>{{data.title}}</h2>
</div>
<mat-dialog-content class="mat-typography">
  <iframe width="100%" height="450" [src]="data.video | safeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  <p>{{data.description}}</p>
  <p>{{data.url}}</p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <a mat-button [mat-dialog-close]="true" href="https://docs.google.com/forms/d/e/1FAIpQLSeAs12KY-tHqLCXHB1p9BZ777ciizTCRCyAxkXQh-n00smnsw/viewform?usp=sf_link" target="_blank" cdkFocusInitial>Word deel</a>
</mat-dialog-actions>