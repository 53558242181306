import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import SignaturePad from 'signature_pad';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

interface DialogData {
  name: string;
  surname: string;
}
@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements AfterViewInit {
  signPad: any;
  signed = false;
  @ViewChild('signPadCanvas', {static: false}) signaturePadElement: ElementRef;
  @ViewChild('container', {static: false}) container: ElementRef;
  signImage: any;
  constructor(public dialogRef: MatDialogRef<SignaturePadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.signaturePadElement.nativeElement.setAttribute('height', (this.container.nativeElement.offsetHeight+'px'));
      this.signaturePadElement.nativeElement.setAttribute('width', ((this.container.nativeElement.offsetWidth-24)+'px'));
    }, 200);
    
    this.signPad = new SignaturePad(this.signaturePadElement.nativeElement);
  }
  
  startSignPadDrawing(event: Event) {
    // console.log(event);
  }
 
  movedFinger(event: Event) {
  }

  undoSign() {
    const data = this.signPad.toData();
    if (data) {
      data.pop(); 
      this.signPad.fromData(data);
    }
  }

  clearSignPad() {
    this.signPad.clear();
    this.signed = false;
  }
  redoSignPad() {
    this.signPad.clear();
    this.signed = false;
    this.signPad = new SignaturePad(this.signaturePadElement.nativeElement);
  }

  async saveSignPad() {
    if(this.signPad.toData().length > 0) {
      this.trimCanvas();
      const base64ImageData = this.signPad.toDataURL();
      this.signImage = base64ImageData;
      this.dialogRef.close({ data: this.signImage });
    }
  }
  cancel(){
    this.signed = false;
    this.clearSignPad();
    this.dialogRef.close();
  }

  trimCanvas(threshold = 0) {
    const canvasElement = this.signaturePadElement.nativeElement;
    const ctx = canvasElement.getContext('2d'),
        w = canvasElement.width, h = canvasElement.height,
        imageData = ctx.getImageData(0, 0, w, h),
        tlCorner = { x:w+1, y:h+1 },
        brCorner = { x:-1, y:-1 };

    for (let y = 0; y < h; y++) {
        for (let x = 0; x < w; x++) {
            if (imageData.data[((y * w + x) * 4) + 3] > threshold) {
                tlCorner.x = Math.min(x, tlCorner.x);
                tlCorner.y = Math.min(y, tlCorner.y);
                brCorner.x = Math.max(x, brCorner.x);
                brCorner.y = Math.max(y, brCorner.y);
            }
        }
    }

    const cut = ctx.getImageData(tlCorner.x, tlCorner.y, brCorner.x - tlCorner.x, brCorner.y - tlCorner.y);

    canvasElement.width = brCorner.x - tlCorner.x;
    canvasElement.height = brCorner.y - tlCorner.y;

    ctx.putImageData(cut, 0, 0);

    return {width:canvasElement.width, height:canvasElement.height, x:tlCorner.x, y:tlCorner.y};
}
}
