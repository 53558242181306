import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GroupService } from 'src/app/admin/services/group.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';

@Component({
  selector: 'app-search-group',
  templateUrl: './search-group.component.html',
  styleUrls: ['./search-group.component.scss']
})
export class SearchGroupComponent implements OnInit {

  loading = false;
  searchGroupForm: UntypedFormGroup;
  noResults = false;
  constructor(private fb: UntypedFormBuilder, private groupService: GroupService, private sideNavService: SideNavService) { }

  ngOnInit(): void {
      this.searchGroupForm = this.fb.group(
        {
          name: ['']
      });
  }
  search(){
    this.noResults = false;
    this.loading = true;
    this.groupService.searchGroup(this.searchGroupForm.value).then(res => {
      if(res.length > 0){
        this.groupService.groups.next(res);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }
}
