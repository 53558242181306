import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';

import { LocationSearchCriteria } from 'backend/interfaces/location.interface';
import { Location } from '../../../../backend/interfaces/location.interface';
@Injectable({
  providedIn: 'root'
})
export class LocationService {

  locations = new BehaviorSubject<Location[] | null>(null);
  locations$ = this.locations.asObservable();
  locationCount = new BehaviorSubject<number | null>(null);
  locationCount$ = this.locationCount.asObservable();
  openLocation: Location;
  constructor() {}

  saveLocation(location: Location): Promise<Location> {
    return Parse.Cloud.run('saveLocation', { location }).then((result) => {
      return result;
    });
  }

  getLocations(displayLimit?: number, page?: number): Promise<void> {
    return Parse.Cloud.run('getLocations', { displayLimit, page }).then((result) => {
      this.locations.next(result);
    });
  }

  getLocationByName(name: string): Promise<Location> {
    return Parse.Cloud.run('getLocationByName', { name });
  }

  getLocationCount(): Promise<number>{
    return Parse.Cloud.run('getLocationCount').then((result) => {
      this.locationCount.next(result);
    });;
  }

  deleteLocation(id: string): Promise<any>{
    return Parse.Cloud.run('deleteLocation', { id });
  }

  searchLocation(criteria: LocationSearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchLocation', { criteria });
  }

}