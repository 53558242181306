

<div mat-dialog-title class="d-flex justify-content-between">
    <h1>{{data.name}}</h1>
    <button mat-button (click)="close()">Close</button>
    </div>
    <div mat-dialog-content class="text-center d-flex">
        <iframe id="showcaseFile" width="100%" height="100%"></iframe>
        <img style="max-width: 100%;" id="showcaseImage"/>
        <!-- <p>{{data.studentId}}</p> -->
        <!-- <div *ngIf="!data.files[0].mediaMetadata" class="align-items-center scrollable border-primary p-2 mr-2">
        <div *ngFor="let file of data.files" class="d-flex flex-column p-2 fileList">
            <div class="flex-1 d-flex justify-content-between mb-2">
                <div class="text-left">
                    <fa-icon *ngIf="file.mimeType === 'image/png' || file.mimeType === 'image/jpeg'" [icon]="faImage"></fa-icon> 
                    <fa-icon *ngIf="file.mimeType === 'application/pdf'" [icon]="faFilePdf"></fa-icon> 
                    {{file.name}}
                    <br>
                    {{file.modifiedTime}}
                </div>
                <button mat-raised-button (click)="getFile(file.id, file.mimeType)">View</button>
            </div>
            <mat-divider></mat-divider>
          </div>
        </div> -->

        <!-- <div *ngIf="data.files[0].mediaMetadata" class="row scrollable p-2 mr-2 flex-1">
            <div *ngFor="let file of data.files" class="col-md-6" >
                <mat-card class="m-3 p-0 bg-primary-light">
                    <mat-card-header>
                      <mat-card-title class="mt-3">                         
                        <fa-icon [icon]="faImage"></fa-icon> {{file.filename}}
                    </mat-card-title>
                    <mat-card-subtitle>
                        {{file.mediaMetadata.creationTime}}
                    </mat-card-subtitle>
                      
                    </mat-card-header>
                    <mat-card-content class="m-0">
                        <img class="img-fluid" [src]="file.baseUrl"/>
                    </mat-card-content>
                </mat-card>

            </div>
        </div> -->
          <!-- <div *ngIf="!data.files[0].mediaMetadata" class="relative flex-1" >

            <div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
                <div>
                    <mat-spinner color="accent"></mat-spinner>
                </div>
            </div>
            <div class="showcase scrollable">
            <ng-container>
                <img style="max-width: 100%;" id="showcaseImage"/>
            </ng-container>
            <ng-container>
                <iframe id="showcaseFile" width="100%" height="100%"></iframe>
            </ng-container>
        </div> -->


    </div>
    <!-- <div *ngIf="data.type === 'drive'" mat-dialog-actions>
        <input
        type="file"
        class="file-input"
        (change)="onFileSelected($event)"
        #fileInput

      /><button mat-stroked-button color="primary" (click)="uploadFile()">upload</button>
    </div> -->
    <!-- <div *ngIf="data.type === 'photos'" mat-dialog-actions>
        <input
        type="file"
        class="file-input"
        (change)="onPhotoSelected($event)"
        #fileInput

      /><button mat-stroked-button color="primary" (click)="uploadPhoto()">upload</button>
    </div> -->
