<div class="container mt-5">
    <div class="form-row" style="margin-left:0px;margin-right:0px;padding:10px;">
        <div class="col-md-6" style="padding-left:20px;padding-right:20px;">
            <fieldset></fieldset>
            <legend><i class="fa fa-info"></i>&nbsp;Contact Information</legend>
            <p></p>
            <div class="table-responsive">
                <table class="table">
                    <tbody>
                        <tr>
                            <td><i class="fa fa-map-marker"></i></td>
                            <td>address
                                
                            </td>
                        </tr>
                        <tr>
                            <td><i class="fa fa-phone"></i></td>
                            <td>phone number </td>
                        </tr>
                        <tr>
                            <td><i class="fa fa-envelope"></i></td>
                            <td><a href="mailto: ">email</a></td>
                        </tr>
                        <tr>
                            <td><i class="icon ion-social-facebook" style="font-size: 21px;"></i></td>
                            <td><a href="">facebook</a></td>
                        </tr>
                        <tr>
                            <td><i class="icon ion-social-instagram" style="font-size: 20px;"></i></td>
                            <td><a href="">instagram</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-12 col-md-6" id="message" style="padding-right:20px;padding-left:20px;">
            <fieldset>
                <legend><i class="fa fa-envelope"></i>&nbsp;Contact us</legend>
            </fieldset>
            <form class="english" name="sentMessage" id="contactForm" novalidate>
                <div class=" control-group">
                    <div class="form-group controls">
                        <label class="english">Name</label>
                        <input type="text" class="form-control english" placeholder="Name" id="name" required data-validation-required-message="Please enter your name.">
                        <p class="help-block text-danger"></p>
                    </div>
                </div>
                <div class=" control-group">
                    <div class="form-group controls">
                        <label class="english">Email Address</label>
                        <input type="email" class="form-control english" placeholder="Email Address" id="email" required data-validation-required-message="Please enter your email address.">
                        <p class="help-block text-danger"></p>
                    </div>
                </div>
                <div class=" control-group">
                    <div class="form-group controls">
                        <label class="english">Phone Number</label>
                        <input type="tel" class="form-control english" placeholder="Phone Number" id="phone" required data-validation-required-message="Please enter your phone number.">
                        <p class="help-block text-danger"></p>
                    </div>
                </div>
                <div class=" control-group">
                    <div class="form-group controls">
                        <label class="english">Message</label>
                        <textarea rows="5" class="form-control english" placeholder="Message" id="message" required data-validation-required-message="Please enter a message."></textarea>
                        <p class="help-block text-danger"></p>
                    </div>
                </div>
                <br>
                <div id="success"></div>
                <div class="">
                    <div class="form-group col-xs-12">
                        <div class="g-recaptcha" data-sitekey="6Leem7sZAAAAALTJDpbz3YwrJ359C70Cbb5-L330"></div>
                        <button type="submit" class="btn btn-primary btn-block">
                            Send
                        </button>
                    </div>
                </div>
            </form>
            <hr>
        </div>
    </div>


</div>