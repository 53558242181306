<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div *ngIf="!loading && context === 'view' && mealPlan" class="justify-content-center text-center d-flex flex-wrap">
  <div class="flex-1 mb-3 min-w-170">
  <h3 class="mb-1 bg-dark text-white">Monday</h3>
  <div class="img-sm" *ngIf="mealPlan.monday?.length < 1">
    <fa-icon
      style="display: inline-block"
      [icon]="faLoader"
      class="fa-spin"
    ></fa-icon>
  </div>
  <div
    *ngFor="let item of mealPlan.monday; let i = index"
    class="bg-image-container d-flex align-items-center justify-content-center"
  >
    <div
      *ngIf="!item.photo || item.photo?.source === ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img class="img-fluid" src="../../../assets/placeholder_food.png" />
    </div>
    <div
      *ngIf="item.photo && item.photo?.source !== ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img [src]="item.photo.source" />
    </div>
    <p class="m-0">{{ item.title }}</p>
  </div>
  </div>

  <div class="flex-1 mb-3 min-w-170">
  <h3 class="mb-1 bg-dark text-white">Tuesday</h3>
  <div *ngIf="mealPlan.tuesday?.length < 1">
    <fa-icon
      style="display: inline-block"
      [icon]="faLoader"
      class="fa-spin"
    ></fa-icon>
  </div>
  <div
    *ngFor="let item of mealPlan.tuesday; let i = index"
    class="bg-image-container d-flex align-items-center justify-content-center"
  >
    <div
      *ngIf="!item.photo || item.photo?.source === ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img class="img-fluid" src="../../../assets/placeholder_food.png" />
    </div>
    <div
      *ngIf="item.photo && item.photo?.source !== ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img [src]="item.photo.source" />
    </div>
    <p class="m-0">{{ item.title }}</p>
  </div>
  </div>

  <div class="flex-1 mb-3 min-w-170">
  <h3 class="mb-1 bg-dark text-white">Wednesday</h3>
  <div class="img-sm" *ngIf="mealPlan.wednesday.length < 1">
    <fa-icon
      style="display: inline-block"
      [icon]="faLoader"
      class="fa-spin"
    ></fa-icon>
  </div>
  <div
    *ngFor="let item of mealPlan.wednesday; let i = index"
    class="bg-image-container d-flex align-items-center justify-content-center"
  >
    <div
      *ngIf="!item.photo || item.photo?.source === ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img class="img-fluid" src="../../../assets/placeholder_food.png" />
    </div>
    <div
      *ngIf="item.photo && item.photo?.source !== ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img [src]="item.photo.source" />
    </div>
    <p class="m-0">{{ item.title }}</p>
  </div>
  </div>

  <div class="flex-1 mb-3 min-w-170">
  <h3 class="mb-1 bg-dark text-white">Thursday</h3>
  <div class="img-sm" *ngIf="mealPlan.thursday?.length < 1">
    <fa-icon
      style="display: inline-block"
      [icon]="faLoader"
      class="fa-spin"
    ></fa-icon>
  </div>
  <div
    *ngFor="let item of mealPlan.thursday; let i = index"
    class="bg-image-container d-flex align-items-center justify-content-center"
  >
    <div
      *ngIf="!item.photo || item.photo?.source === ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img class="img-fluid" src="../../../assets/placeholder_food.png" />
    </div>
    <div
      *ngIf="item.photo && item.photo?.source !== ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img [src]="item.photo.source" />
    </div>
    <p class="m-0">{{ item.title }}</p>
  </div>
  </div>

  <div class="flex-1 mb-3 min-w-170">
  <h3 class="mb-1 bg-dark text-white">Friday</h3>
  <div class="img-sm" *ngIf="mealPlan.friday.length < 1">
    <fa-icon
      style="display: inline-block"
      [icon]="faLoader"
      class="fa-spin"
    ></fa-icon>
  </div>
  <div
    *ngFor="let item of mealPlan.friday; let i = index"
    class="bg-image-container d-flex align-items-center justify-content-center"
  >
    <div
      *ngIf="!item.photo || item.photo?.source === ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img class="img-fluid" src="../../../assets/placeholder_food.png" />
    </div>
    <div
      *ngIf="item.photo && item.photo?.source !== ''"
      class="bg-image d-flex align-items-center justify-content-center"
    >
      <img [src]="item.photo.source" />
    </div>
    <p class="m-0">{{ item.title }}</p>
  </div>
  </div>
</div>
<div class="scrollable" *ngIf="!loading && context === 'edit' && mealPlan" cdkDropListGroup>
  <form
    class="user mb-5 h-100"
    [formGroup]="mealPlanForm"
    (ngSubmit)="saveMealPlan()"
  >
    <div class="row m-0">
      <div class="col-md-8 flex-1 row m-0 bg-white">

          <div class="p-3 w-100 border-primary mb-3 mt-3 d-flex justify-content-between">
            <mat-form-field class="mr-2 flex-1 input-md">
              <mat-label>Title</mat-label>
              <input matInput formControlName="title" placeholder="title" />
            </mat-form-field>
            <!-- <mat-form-field class="mr-2 flex-1 input-md">
              <mat-label>Group</mat-label>
              <input matInput formControlName="group" placeholder="group" />
            </mat-form-field> -->
  
            <mat-form-field color="accent" class="input-md mr-2">
              <mat-label>From</mat-label>
              <input
                readonly
                matInput
                [matDatepicker]="picker1"
                formControlName="fromDate"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field color="accent" class="input-md mr-2">
              <mat-label>To</mat-label>
              <input
                readonly
                matInput
                [matDatepicker]="picker2"
                formControlName="toDate"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>

        <div class="row m-0 w-100">
            <div class="example-list col-md-4 mb-3 p-2 bg-dark text-white"
              cdkDropList
              [cdkDropListData]="mealPlan.monday"
              cdkDropListSortingDisabled
              (cdkDropListDropped)="drop($event)"
            >
              <h3>Monday</h3>
              <ng-container *ngFor="let item of mealPlan.monday; let i = index">
                <div class="example-box" cdkDrag>
                  <div
                    class="d-flex align-items-center bg-image-container draggable flex-1"
                  >
                    <div
                      *ngIf="!item.photo || item.photo?.source === ''"
                      class="bg-image"
                    >
                      <img
                        class="img-fluid"
                        src="../../../assets/placeholder_food.png"
                      />
                    </div>
                    <div
                      *ngIf="item.photo && item.photo?.source !== ''"
                      class="bg-image"
                    >
                      <img [src]="item.photo.source" />
                    </div>
                    <p class="m-0">{{ item.title }}</p>
                  </div>
                  <fa-icon
                    class="ml-3 mr-3 link"
                    [icon]="faTrash"
                    (click)="removeMeal(i, mealPlan.monday)"
                  ></fa-icon>
                </div>
              </ng-container>
            </div>
            <div class="example-list col-md-4 mb-3 p-2 bg-dark text-white"
              cdkDropList
              [cdkDropListData]="mealPlan.tuesday"
              cdkDropListSortingDisabled
              (cdkDropListDropped)="drop($event)"
            >
              <h3>Tuesday</h3>
              <ng-container *ngFor="let item of mealPlan.tuesday; let i = index">
                <div class="example-box" cdkDrag>
                  <div
                    class="d-flex align-items-center bg-image-container draggable flex-1"
                  >
                    <div
                      *ngIf="!item.photo || item.photo?.source === ''"
                      class="bg-image"
                    >
                      <img
                        class="img-fluid"
                        src="../../../assets/placeholder_food.png"
                      />
                    </div>
                    <div
                      *ngIf="item.photo && item.photo?.source !== ''"
                      class="bg-image"
                    >
                      <img [src]="item.photo.source" />
                    </div>
                    <p class="m-0">{{ item.title }}</p>
                  </div>
                  <fa-icon
                    class="ml-3 mr-3 link"
                    [icon]="faTrash"
                    (click)="removeMeal(i, mealPlan.tuesday)"
                  ></fa-icon>
                </div>
              </ng-container>
            </div>
            <div class="example-list col-md-4 mb-3 p-2 bg-dark text-white"
              cdkDropList
              [cdkDropListData]="mealPlan.wednesday"
              cdkDropListSortingDisabled
              (cdkDropListDropped)="drop($event)"
            >
              <h3>Wednesday</h3>
              <ng-container *ngFor="let item of mealPlan.wednesday; let i = index">
                <div class="example-box" cdkDrag>
                  <div
                    class="d-flex align-items-center bg-image-container draggable flex-1"
                  >
                    <div
                      *ngIf="!item.photo || item.photo?.source === ''"
                      class="bg-image"
                    >
                      <img
                        class="img-fluid"
                        src="../../../assets/placeholder_food.png"
                      />
                    </div>
                    <div
                      *ngIf="item.photo && item.photo?.source !== ''"
                      class="bg-image"
                    >
                      <img [src]="item.photo.source" />
                    </div>
                    <p class="m-0">{{ item.title }}</p>
                  </div>
                  <fa-icon
                    class="ml-3 mr-3 link"
                    [icon]="faTrash"
                    (click)="removeMeal(i, mealPlan.wednesday)"
                  ></fa-icon>
                </div>
              </ng-container>
            </div>
            <div class="example-list col-md-4 mb-3 p-2 bg-dark text-white"
              cdkDropList
              [cdkDropListData]="mealPlan.thursday"
              cdkDropListSortingDisabled
              (cdkDropListDropped)="drop($event)"
            >
              <h3>Thursday</h3>
              <ng-container *ngFor="let item of mealPlan.thursday; let i = index">
                <div class="example-box" cdkDrag>
                  <div
                    class="d-flex align-items-center bg-image-container draggable flex-1"
                  >
                    <div
                      *ngIf="!item.photo || item.photo?.source === ''"
                      class="bg-image"
                    >
                      <img
                        class="img-fluid"
                        src="../../../assets/placeholder_food.png"
                      />
                    </div>
                    <div
                      *ngIf="item.photo && item.photo?.source !== ''"
                      class="bg-image"
                    >
                      <img [src]="item.photo.source" />
                    </div>
                    <p class="m-0">{{ item.title }}</p>
                  </div>
                  <fa-icon
                    class="ml-3 mr-3 link"
                    [icon]="faTrash"
                    (click)="removeMeal(i, mealPlan.thursday)"
                  ></fa-icon>
                </div>
              </ng-container>
            </div>
            <div class="example-list col-md-4 mb-3 p-2 bg-dark text-white"
              cdkDropList
              [cdkDropListData]="mealPlan.friday"
              cdkDropListSortingDisabled
              (cdkDropListDropped)="drop($event)"
            >
              <h3>Friday</h3>
              <ng-container *ngFor="let item of mealPlan.friday; let i = index">
                <div class="example-box" cdkDrag>
                  <div
                    class="d-flex align-items-center bg-image-container draggable flex-1"
                  >
                    <div
                      *ngIf="!item.photo || item.photo?.source === ''"
                      class="bg-image"
                    >
                      <img
                        class="img-fluid"
                        src="../../../assets/placeholder_food.png"
                      />
                    </div>
                    <div
                      *ngIf="item.photo && item.photo?.source !== ''"
                      class="bg-image"
                    >
                      <img [src]="item.photo.source" />
                    </div>
                    <p class="m-0">{{ item.title }}</p>
                  </div>
                  <fa-icon
                    class="ml-3 mr-3 link"
                    [icon]="faTrash"
                    (click)="removeMeal(i, mealPlan.friday)"
                  ></fa-icon>
                </div>
              </ng-container>
            </div>
          </div>

        
      </div>

      <div class="col-md-4 meals-container">
        <p class="mb-3">
          Drag the meals below to the day you plan to serve them.
        </p>
        <ng-container *ngIf="mealsObservable | async as mealSubscription">
          <div
            cdkDropList
            [cdkDropListData]="mealSubscription"
            class="row m-0 mt-3"
            (cdkDropListDropped)="drop($event)"
          >
            <ng-container *ngFor="let meal of mealSubscription">
              <div class="col-md-6 mb-3" cdkDrag>
                <div
                  class="d-flex align-items-center bg-image-container draggable flex-1"
                >
                  <div
                    *ngIf="!meal.photo || meal.photo?.source === ''"
                    class="bg-image"
                  >
                    <img
                      class="img-fluid"
                      src="../../../assets/placeholder_food.png"
                    />
                  </div>
                  <div
                    *ngIf="meal.photo && meal.photo?.source !== ''"
                    class="bg-image"
                  >
                    <img [src]="meal.photo.source" />
                  </div>
                  <p class="ml-2 m-0 ml-2">{{ meal.title }}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <mat-toolbar class="d-flex justify-content-between">
        <button
          *ngIf="mealPlan.id"
          type="button"
          (click)="openDeleteDialog()"
          mat-stroked-button
          color="accent"
        >
          Delete
        </button>
        <button type="submit" mat-raised-button color="primary">Save</button>
      </mat-toolbar>
    </div>
  </form>
</div>
