import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faEllipsisH, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MealPlan } from 'backend/interfaces/meal-plan.interface';
import { Meal } from 'backend/interfaces/meal.interface';
import { MealPlanService } from 'src/app/admin/services/meal-plan.service';
import { MealService } from 'src/app/admin/services/meal.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
  CdkDrag,
  CdkDropList,
} from '@angular/cdk/drag-drop';
import { AuthService } from 'src/app/auth/auth.service';
import { StudentService } from 'src/app/admin/services/student.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
export enum Allergies {
  MILK = 'Milk',
  WHEAT = 'Wheat',
  EGG = 'Egg'

}
@Component({
  selector: 'app-meal-plan',
  templateUrl: './meal-plan.component.html',
  styleUrls: ['./meal-plan.component.scss']
})
export class MealPlanComponent implements OnInit {

  loading = false;
  faPlus = faPlus;
  faTrash = faTrash;
  faLoader = faEllipsisH;
  @Input('context') context = 'view';

  mealPlan: MealPlan = {
    id: '',
    title: "",
    group: "",
    fromDate: null,
    toDate: null,
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: []
  };
  mealsObservable = this.mealService.meals;
  m = [];
  isAdmin = false;
  mealPlanId = '';
  mealPlanForm = this.fb.group({
    title: this.mealPlan?.title ? this.mealPlan.title : '',
    group: this.mealPlan?.group ? this.mealPlan.group : '',
    fromDate: this.mealPlan?.fromDate ? this.mealPlan.fromDate : null,
    toDate: this.mealPlan?.toDate ? this.mealPlan.toDate : null
  })
  constructor(private authService: AuthService, private fb: FormBuilder, private mealPlanService: MealPlanService, private mealService: MealService, private _snackBar: MatSnackBar, private ar: ActivatedRoute, private router: Router, private studentService: StudentService, private dialog: MatDialog) {
    this.authService.$User.subscribe((res) => {
      this.isAdmin = res?.get('role') === 'admin';
    })
  }


  async ngOnInit() {
    this.loading = true;
    
    if (this.ar.snapshot.params['id']) {
      this.context = 'edit';
    }
    this.mealPlanService.activatedMealPlan$.subscribe(async res => {
      if (res) {
        this.mealPlan.monday = [];
        this.mealPlan.tuesday = [];
        this.mealPlan.wednesday = [];
        this.mealPlan.thursday = [];
        this.mealPlan.friday = [];
        this.mealPlan.title = res.title;
        this.mealPlan.id = res.id;
        this.mealPlan.fromDate = res.fromDate;
        this.mealPlan.toDate = res.toDate;
        this.mealPlan.group = res.group;
        this.mealService.meals$.subscribe(ms => {
          res.monday?.forEach(meal => ms?.find(target => {
            if (target.id === meal) {
              this.mealPlan.monday.push(target);
            }
          }));
          res.tuesday?.forEach(meal => ms?.find(target => {
            if (target.id === meal) {
              this.mealPlan.tuesday.push(target);
            }
          }));
          res.wednesday?.forEach(meal => ms?.find(target => {
            if (target.id === meal) {
              this.mealPlan.wednesday.push(target);
            }
          }));
          res.thursday?.forEach(meal => ms?.find(target => {
            if (target.id === meal) {
              this.mealPlan.thursday.push(target);
            }
          }));
          res.friday?.forEach(meal => ms?.find(target => {
            if (target.id === meal) {
              this.mealPlan.friday.push(target);
            }
          }));
        });
        await this.mealPlanForm.patchValue({
          title: this.mealPlan?.title ? this.mealPlan.title : '',
          group: this.mealPlan?.group ? this.mealPlan.group : '',
          fromDate: this.mealPlan?.fromDate ? this.mealPlan.fromDate : null,
          toDate: this.mealPlan?.toDate ? this.mealPlan.toDate : null
        })
      }
    });
    this.loading = false;
  }

  get monday() {
    return this.mealPlanForm.controls["monday"] as FormArray;
  }

  get tuesday() {
    return this.mealPlanForm.controls["tuesday"] as FormArray;
  }

  get wednesday() {
    return this.mealPlanForm.controls["wednesday"] as FormArray;
  }

  get thursday() {
    return this.mealPlanForm.controls["thursday"] as FormArray;
  }

  get friday() {
    return this.mealPlanForm.controls["friday"] as FormArray;
  }


  addMeal(dayMeals: FormArray) {
    const mealForm = this.fb.group({
      title: [''],
      photo: ['']
    });
    dayMeals.push(mealForm);
  }
  removeMeal(mealIndex: number, dayMeals: Meal[]) {
    dayMeals.splice(mealIndex, 1);
  }

  async saveMealPlan() {
    this.loading = true;
    let updatedMealPlan;
    if (this.mealPlan.id) {
      updatedMealPlan = {
        ...this.mealPlanForm.value,
        id: this.mealPlan.id,
        monday: this.mealPlan.monday.map(m => m.id),
        tuesday: this.mealPlan.tuesday.map(m => m.id),
        wednesday: this.mealPlan.wednesday.map(m => m.id),
        thursday: this.mealPlan.thursday.map(m => m.id),
        friday: this.mealPlan.friday.map(m => m.id)
      };
    } else {
      updatedMealPlan = {
        ...this.mealPlanForm.value,
        monday: this.mealPlan.monday.map(m => m.id),
        tuesday: this.mealPlan.tuesday.map(m => m.id),
        wednesday: this.mealPlan.wednesday.map(m => m.id),
        thursday: this.mealPlan.thursday.map(m => m.id),
        friday: this.mealPlan.friday.map(m => m.id)
      };
    }
    this.mealPlanService.saveMealPlan(updatedMealPlan).then(res => {
      this.mealPlanService.getMealPlans();
      this.mealPlanService.activatedMealPlan.next(null);
      this.router.navigate(['admin', 'meal-plans']);
      this.loading = false;
    })
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  async deleteMealPlan() {
    this.loading = true;
    this.mealPlanService.deleteMealPlan(this.mealPlan.id).then(res => {
      this.mealPlanService.getMealPlans(10, 0).then(res => {
        this.router.navigate(['admin', 'meal-plans']);
        this.loading = false;
      })
    });
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { title: 'Delete', question: 'Are you sure you want to delete this item?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result) {
          this.deleteMealPlan();
        } else {
          this.dialog.closeAll();
        }
      } else {
        this.dialog.closeAll();
      }
    });
  }


  mondayMeals = [];
  tuesdayMeals = [];
  wednesdayMeals = [];
  thursdayMeals = [];
  fridayMeals = [];


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const data = {
        title: '',
        id: ''
      }

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

}