import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, RendererStyleFlags2, ViewChild } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import * as faceapi from 'face-api.js';
@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
  @Output('capturedImage') capturedImage: EventEmitter<any> = new EventEmitter();
  @Output('smileMeter') smileMeter: EventEmitter<any> = new EventEmitter();
  loading = true;
  showWebcam = true;
  allowCameraSwitch = false;
  multipleWebcamsAvailable = false;
  deviceId: string;
  facingMode: string = 'user';
  messages: any[] = [];
  timer: number = 1;
  timerText = 'Getting Ready';
  myInterval;
  // latst snapshot
  webcamImage: WebcamImage = null;stream: any;
  detection: any;
  resizedDetections: any;
  canvas: any;
  canvasEl: any;
  displaySize: any;
  videoInput: any;
  showSnapshotAnimation = false;
  label = 'undefined';
  data = 0;
  @ViewChild('cam') cam: any;
  @ViewChild('cancancanvas') cancancanvas: ElementRef;
  
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  constructor(private renderer: Renderer2) { 
    Promise.all([faceapi.nets.tinyFaceDetector.loadFromUri('./assets/models/'),
      faceapi.nets.faceLandmark68Net.loadFromUri('./assets/models/')]).then(() => {
        this.detectFaces();
      });
      
  }

  async ngOnInit(): Promise<void> {
      this.readAvailableVideoInputs();
    }
  

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.messages.push(error);
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      this.addMessage('User denied camera access');
    }
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.addMessage('Received webcam image');
    this.webcamImage = webcamImage;
    // this.toggleWebcam();
    setTimeout(() => {
      this.showSnapshotAnimation = false;
      this.capturedImage.emit({image: this.webcamImage, detection: this.detection});
    }, 500);
  }

  public cameraWasSwitched(deviceId: string): void {
    this.addMessage('Active device: ' + deviceId);
    this.deviceId = deviceId;
    this.readAvailableVideoInputs();
  }

  addMessage(message: any): void {
    this.messages.unshift(message);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }

    return result;
  }
  async detectFaces(){
    this.loading = true;
    this.showSnapshotAnimation = true;
    this.displaySize = await {
      width: this.cam?.video.nativeElement.width,
      height: this.cam?.video.nativeElement.height,
   }
    // console.log('this.cam.nativeElement: ', this.cam.canvas.nativeElement.getContext('2d'))
    faceapi.matchDimensions(this.cancancanvas.nativeElement, this.displaySize);
      this.renderer.setStyle(this.cancancanvas.nativeElement, 'display', 'block');
      this.renderer.setStyle(this.cancancanvas.nativeElement, 'position', 'absolute');
      // this.renderer.setStyle(this.cancancanvas.nativeElement, 'top', '0px');
      // this.renderer.setStyle(this.cancancanvas.nativeElement, 'left', '0px');
      // this.renderer.setStyle(this.cancancanvas.nativeElement, 'right', '0px');
      // this.renderer.setStyle(this.cancancanvas.nativeElement, 'bottom', '0px');
      this.renderer.setStyle(this.cancancanvas.nativeElement, 'margin', 'auto');
      this.renderer.setStyle(this.cancancanvas.nativeElement, 'height', this.cam.video.nativeElement.height, RendererStyleFlags2.Important);
      this.renderer.setStyle(this.cancancanvas.nativeElement, 'width', this.cam.video.nativeElement.width, RendererStyleFlags2.Important);
      const ctx = this.cancancanvas.nativeElement.getContext("2d");

      // ctx.fillStyle = "#58A8D8";
      // console.log(this.cam)
      let flag = false;

      let intervalID = setInterval(async () => {
          //api stuff
          // const detections = await faceapi
          //   .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions());
          // const resizedDetections = faceapi.resizeResults(detections, displaySize);
          // const happy  =resizedDetections[0].expressions.happy;
      
          // //The Issue  starts from here
          // if(flag && happy>0.9)
          // {
          //   audio.src="audios/happy_audio.wav";
          //   audio.play();
          //   flag = true;
          // }
      
          // faceapi.draw.drawDetections(canvas, resizedDetections);
          // faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
      this.detection = await faceapi.detectSingleFace(this.cam?.video.nativeElement,  new  faceapi.TinyFaceDetectorOptions()).withFaceLandmarks();
      if(this.detection){
      this.resizedDetections = await faceapi.resizeResults(
         this.detection,
         this.displaySize
       );
      //  this.data = this.detection.expressions.happy*100;
      //  this.label = (this.detection.expressions.happy*100).toString();
      // const neutral= await this.detection.expressions.neutral*10;
          // this.smileMeter.emit(this.detection.expressions);
          // if(neutral > 0.01)
          // {
            flag = true;
            // const cancanx = this.cancancanvas.nativeElement.getContext("2d");
            // cancanx.clearRect(0, 0, this.cam.video?.nativeElement.width,this.cam.video.nativeElement.height);
            // faceapi.draw.drawDetections(this.cancancanvas.nativeElement, this.resizedDetections);
            faceapi.draw.drawFaceLandmarks(this.cancancanvas.nativeElement, this.resizedDetections);
            // faceapi.draw.drawFaceExpressions(this.cancancanvas.nativeElement, this.resizedDetections);
            clearInterval(intervalID);
            // console.log('got you')
          // }else{
          //   console.log('smile', flag)
          // }

      // }
      // ctx.clearRect(0, 0, this.cam.video.nativeElement.width,this.cam.video.nativeElement.height);
      // faceapi.draw.drawDetections(this.cam.canvas.nativeElement, this.resizedDetections);
      // faceapi.draw.drawFaceLandmarks(this.cam.canvas.nativeElement, this.resizedDetections);
      // faceapi.draw.drawFaceExpressions(this.cam.canvas.nativeElement, this.resizedDetections);

      }

      if(flag){
        this.myInterval = setInterval(() => {
          if (this.timer > 0) {
            this.timer = this.timer - 1;
            this.timerText = 'Looking good';
          } else {
            clearInterval(this.myInterval);
            this.triggerSnapshot();
          }
        }, 800);}
    },500);
      
      

  }

  private readAvailableVideoInputs() {
    this.loading = true;
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {

      // console.log('text')
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
      // setTimeout(async () => {
      // this.detectFaces();
      // },500)
  }
  
}
