<div class="row bg-white p-4 letter-head">
    <div class="col-md-3 text-center">
      <img class="logo img-fluid" src="assets/icon.png" />
    </div>
    <div
      class="col-md-4 d-flex justify-content-center align-items-center flex-column"
    >
      <h4>placeholder practitioner name</h4>
      <h2>placeholder practice number</h2>
    </div>
    <div
      class="col-md-5 d-flex justify-content-center align-items-start flex-column"
    >
      <div class="d-flex">
        <fa-icon [icon]="faPhone"></fa-icon
        ><span class="ml-2">placeholder for phone number</span>
      </div>
      <div class="d-flex">
        <fa-icon [icon]="faEnvelope"></fa-icon
        ><span class="ml-2">placeholder for email</span>
      </div>
      <div class="d-flex">
        <fa-icon [icon]="faHome"></fa-icon
        ><span class="ml-2">placeholder for address</span>
      </div>
    </div>
  </div>