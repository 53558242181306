import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';

import { LocationSearchCriteria } from 'backend/interfaces/location.interface';
import { Location } from '../../../../backend/interfaces/location.interface';
import { Practitioner, PractitionerSearchCriteria } from 'backend/interfaces/practitioner.interface';
import { Member } from 'backend/interfaces/member.interface';
@Injectable({
  providedIn: 'root'
})
export class PractitionerService {

  practitioners = new BehaviorSubject<Member[] | null>(null);
  practitioners$ = this.practitioners.asObservable();
  practitionerCount = new BehaviorSubject<number | null>(null);
  practitionerCount$ = this.practitionerCount.asObservable();
  openPractitioner: Member;
  constructor() {}

  savePractitioner(practitioner: Practitioner): Promise<Practitioner> {
    return Parse.Cloud.run('savePractitioner', { practitioner }).then((result) => {
      return result;
    });
  }

  getPractitioners(displayLimit: number, page: number): Promise<void> {
    return Parse.Cloud.run('getPractitioners', { displayLimit, page }).then((result) => {
      this.practitioners.next(result);
    });
  }

  getPractitionerByName(name: string): Promise<Practitioner> {
    return Parse.Cloud.run('getPractitionerByName', { name });
  }

  getPractitionerById(id: string): Promise<Practitioner> {
    return Parse.Cloud.run('getPractitionerById', { id });
  }

  getPractitionersByGroup(group: string): Promise<Practitioner[]> {
    return Parse.Cloud.run('getPractitionersByGroup', { group });
  }

  getPractitionerCount(): Promise<number>{
    return Parse.Cloud.run('getPractitionerCount').then((result) => {
      this.practitionerCount.next(result);
    });;
  }

  deletePractitioner(name: string): Promise<any>{
    return Parse.Cloud.run('deleteLocation', { name });
  }

  searchPractitioner(criteria: PractitionerSearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchPractitioner', { criteria });
  }

  savePractitionerProfilePicture(practitionerId: string, image: any): Promise<any>{
    return Parse.Cloud.run('savePractitionerProfilePicture', { practitionerId, image });
  }

}