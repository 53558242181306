
<mat-progress-bar
    [mode]="loading ? 'indeterminate' : 'determinate'"
    [value]="0">
</mat-progress-bar>
<form *ngIf="searchParentForm" [formGroup]="searchParentForm" (submit)="search()">
    <div class="d-flex row p-3">
    <div class="col-md-12 d-flex flex-column">
        <mat-form-field appearance="outline">
            <mat-label>name</mat-label>
            <input matInput placeholder="name" name="name" formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>surname</mat-label>
            <input matInput placeholder="surname" name="surname" formControlName="surname">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>mobile</mat-label>
            <input matInput placeholder="mobile" name="mobile" formControlName="mobile">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>email</mat-label>
            <input matInput placeholder="email" name="email" formControlName="email">
        </mat-form-field>
        <button *ngIf="searchPhrase && (searchPhrase?.name !== '' || searchPhrase?.surname !== '' || searchPhrase?.mobile !== '' || searchPhrase?.email !== '')" mat-stroked-button (click)="clearSearch()">
            <fa-layers size="2x">
              <fa-icon class="link" [icon]="faSearch"></fa-icon>
              <fa-layers-counter [content]="'X'"></fa-layers-counter>
            </fa-layers>
            Clear Search
          </button>
    </div>
    </div>
    <div mat-toolbar class="d-flex justify-content-between p-3">
        {{noResults ? 'No Results Found' : ''}}
        <div class="spacer"></div>
        <button mat-raised-button color="accent" [disabled]="loading" type="submit" class="btn btn-outline-secondary">
            {{ loading ? 'Searching' : 'Search' }}
        </button>
    </div>
</form>
