import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  loading = false;
  contactForm: UntypedFormGroup;
  constructor(private fb: FormBuilder, private dataService: DataService, private _snackBar: MatSnackBar) { }
  ngOnInit(): void {
      this.contactForm = this.fb.group(
        {
          name: [''],
          number: [''],
          message: ['']
      });
  }
  submit(): void{
    this.loading=true;
    this.loading=true;
    this.dataService.contactUsEmail(this.contactForm.value).then(res => {
      this.openSnackBar('Boodskap gestuur. Dankie.');
      this.loading = false;
    })
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

}
