import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { StudentService } from 'src/app/admin/services/student.service';
import { PractitionerService } from 'src/app/admin/services/practitioner.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';


@Component({
  selector: 'app-search-practitioner',
  templateUrl: './search-practitioner.component.html',
  styleUrls: ['./search-practitioner.component.scss']
})
export class SearchPractitionerComponent implements OnInit {

  loading = false;
  searchPractitionerForm: UntypedFormGroup;
  noResults = false;
  constructor(private fb: UntypedFormBuilder, private PractitionerService: PractitionerService, private sideNavService: SideNavService) { }

  ngOnInit(): void {
      this.searchPractitionerForm = this.fb.group(
        {
          name: [''],
          surname: ['']
      });
  }
  search(){
    this.noResults = false;
    this.loading = true;
    this.PractitionerService.searchPractitioner(this.searchPractitionerForm.value).then(res => {
      if(res.length > 0){
        this.PractitionerService.practitioners.next(res);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }
}
