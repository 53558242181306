import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import * as Parse from "parse";
import { ParseClasses } from 'backend/enum/ParseClasses.enum';
import { faChevronRight, faPaperPlane, faTimes, faUser, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { ParsePointer } from 'backend/interfaces/ParsePointer';
import { PractitionerService } from 'src/app/admin/services/practitioner.service';
import { DataService } from 'src/app/services/data.service';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Member } from 'backend/interfaces/member.interface';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit {
  // @Input('user') user: string;
  // @Input('chatId') chatId: string;
  teachers: Member[];
  contacts;
  conversation = false;
  faClose = faTimes;
  faUser = faUser;
  faChevronRight = faChevronRight;
  recipient: Practitioner;
  loading = false;
  @Output() emitClose = new EventEmitter<Boolean>();
  constructor(private dataService: DataService) { }
  currentUser = Parse.User.current().get('Name')
  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.dataService.practitioners$.subscribe(res => this.teachers = res);
    const query = new Parse.Query(ParseClasses.USER);
    query.find().then(async res => {
      this.contacts = await res;
      this.loading = false;
    })
  }

  ngAfterViewInit(): void {
  }

  close():void{
    // this.chatService.showChat.next(false);
      // this.chatService.showChat.next(false);
      this.emitClose.emit(true)
      this.conversation = false;

  }

  openConversation(recipient: Parse.User){
    this.conversation = true;
    this.recipient = recipient;
  }

  closeConversation(){
    this.conversation = false;
  }

}
