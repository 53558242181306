import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { faCamera, faCheck, faUpload, faUser } from '@fortawesome/free-solid-svg-icons';
import { WebcamImage } from 'ngx-webcam';
import { StudentService } from 'src/app/admin/services/student.service';
import { PractitionerService } from 'src/app/admin/services/practitioner.service';
import { ParentService } from 'src/app/admin/services/parent.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Guid } from "guid-typescript";
import { Router } from '@angular/router';
import { Member } from 'backend/interfaces/member.interface';
import { Student } from 'backend/interfaces/student.interface';
import { StudentCardComponent } from '../student-card/student-card.component';
interface DialogData {
  title: string;
  studentId?: string;
  parentId?: string
  result: boolean;
  currentPicture: string;
  practitionerId?: string;
}
@Component({
  selector: 'app-facial-find-dialog',
  templateUrl: './facial-find-dialog.component.html',
  styleUrls: ['./facial-find-dialog.component.scss']
})
export class FacialFindDialogComponent implements OnInit, AfterViewInit {
  loading = true;
  title;
  question;
  faCheck = faCheck;
  webcamImage: WebcamImage = null;
  uploadedImage: {name: string, source: string | ArrayBuffer, type?: string};
  showCamera = true;
  faCamera = faCamera;
  faUpload = faUpload;
  recognisedFace = '';
  faUser = faUser;
  date = new Date();
  recognitionImage: {name: string, source: string | ArrayBuffer, type?: string};
  recognisedPerson =  {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    id: ''
  };
  parent: Member;
  children;
  @ViewChild('webcamImageDisplay') webcamImageDisplay: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('recognitionFileInput') recognitionFileInput: ElementRef;
  constructor(public dialogRef: MatDialogRef<FacialFindDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private studentService: StudentService, private practitionerService: PractitionerService, private parentService: ParentService, private _snackBar: MatSnackBar, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.parentService.activatedParent$.subscribe(res => this.parent= res);
    setTimeout(() => {
        this.loading = false;
        this.openSnackBar('tap to scan');
    }, 1000);
  }

  ngAfterViewInit(){
    // this.webcamImageDisplay.nativeElement.src = this.data.currentPicture
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showThumb(result: {image: WebcamImage, detection: any}): void {
    this.webcamImage = result.image;
    this.webcamImageDisplay.nativeElement.src = result.image.imageAsDataUrl;
    const name: Guid = Guid.create();
    console.log(result.image);
    this.recognitionImage = {
      source: result.image.imageAsBase64,
      name: name.toString(),
      type: 'image/jpeg',
    }
    this.uploadedImage = {
      source: result.image.imageAsDataUrl,
      name: name.toString(),
      type: 'image/jpeg',
    }
    this.showCamera=false;
    this.recognise();
  }

  async onFileSelected(event): Promise<void>{

    let reader = new FileReader();

    this.webcamImage = null;
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.type.includes('image')) {
          this.webcamImageDisplay.nativeElement.src = reader.result;
          this.uploadedImage = {
            source: reader.result as any,
            name: file.name,
            type: file.type,
          }
        }
      };
      this.recognise();
    }
  }

  toggleCamera(){
    this.showCamera = !this.showCamera;
  }

  uploadFile(){
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  uploadRecognitionFile(){
    this.recognitionFileInput.nativeElement.value = null;
    this.recognitionFileInput.nativeElement.click();
  }

  async saveProfilePicture(): Promise<void>{
    const imageToUpload = this.uploadedImage;
    

    if(this.data.practitionerId){
      this.practitionerService.savePractitionerProfilePicture(this.data.practitionerId, imageToUpload).then(res => {
        this.dialogRef.close();
        this.openSnackBar("Photo successfuly added.");
      }).catch(err => {
        console.log(err);
      });
    }else if(this.data.parentId){
      this.parentService.saveProfilePicture(this.data.parentId, imageToUpload).then(res => {
        this.dialogRef.close();
        this.openSnackBar("Photo successfuly added.");
      }).catch(err => {
        console.log(err);
      });
    }else{
      this.studentService.saveProfilePicture(this.data.studentId, imageToUpload).then(res => {
        this.dialogRef.close();
        this.openSnackBar("Photo successfuly added.");
      }).catch(err => {
        console.log(err);
      });
    }
  }


  async uploadToBucket(): Promise<void>{
    this.parentService.uploadPictureToBucket(this.uploadedImage, this.parent.Name, 'dfaces').then(async res => {
      this.openSnackBar(res.toString());
    }).catch(err => {
      console.log(err);
    });
  }

  async recognise(): Promise<void>{
    this.loading = true
         await this.parentService.uploadPictureToBucket(this.uploadedImage, this.uploadedImage.name, 'drecognitionrequests','').then(async res => {
            this.openSnackBar('Identifiseer persoon');
            this.parentService.authenticate(this.uploadedImage).then(async res => {
              if(res.firstName){
              this.openSnackBar(res.firstName + ' ' + res.lastName);
              this.recognisedPerson.name = res.firstName;
              this.recognisedPerson.surname = res.lastName;
              this.recognisedFace = res.firstName+' '+res.lastName;
              this.recognisedPerson.id  = res.personId;
              // this.dialogRef.close();
              // this.router.navigate(['admin','parent',res.personId]);
              this.parentService.getStudentsByParentId(res.personId, true).then(async res => {
                this.children = await res;
                res?.forEach((s, index) => {
                  this.studentService.getStudentDailyReport(s.id, this.date.toDateString(), this.date.toDateString()).then(sr => this.children[index].Report = sr)
                })
                this.loading = false;
              })
              }else{
                throw(res)
              }
            }).catch(err => {
              this.recognisedFace = err;
              console.log(err);
              this.loading = false;
            });
          }).catch(err => {
            console.log(err);
          });
  }

  
  search(){
  this.loading = true;
  if(this.recognisedPerson.id !== ''){
    this.dialogRef.close();
    this.router.navigate(['admin','member',this.recognisedPerson.id]);
  }else{
  this.parentService.searchParent(this.recognisedPerson).then(res => {
  if(res.parents.length > 0){
    this.parentService.parentSearch.next(this.recognisedPerson);
    this.parentService.parents.next(res.parents);
    this.parentService.parentCount.next(res.count);
    this.dialogRef.close();
    this.router.navigate(['admin','members']);

  } else {
    console.log('no result')
  }

  })
  }
  this.loading = false;
  }
  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000,
      verticalPosition: 'top',
    });
  }


  checkin(studentId: string, reportId?: string){
    this.loading = true;
    let date = new Date();
    this.studentService.saveDailyReport({
      ReportId: reportId || '',
      Date: date.toString(),
      StudentID: studentId,
      CheckInNotes: '',
      CheckInMood: '',
      CheckIn: this.recognisedFace
    }).then(async res => {
      this.loading = false;
    });
  }

  checkout(studentId: string, reportId?: string){
    this.loading = true;
    let date = new Date();
    this.studentService.saveDailyReport({
      ReportId: reportId || '',
      Date: date.toString(),
      StudentID: studentId,
      CheckOutTime: date.toString(),
      CheckedOutBy: this.recognisedFace
    }).then(async res => {
      this.children.find(c => {
        if(c.StudentID === res.StudentID){
          c.CheckOutTime = res.CheckOutTime;
          c.CheckedOutBy = res.CheckedOutBy;
        }
      })
      this.loading = false;
    });
  }

  async printLabel(student: Student):Promise<void>{
    console.log(student)
      this.openStudentCardDialog(student);
}

openStudentCardDialog(student: Student): void {
  const dialogRef = this.dialog.open(StudentCardComponent, {
    data: { student }
  });
}
  
}

