<!-- <div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div> -->
<div class="row m-0 mb-3">
  <div class="col-md-12">
    <div class="w-100 h-100 hero-strip container-fluid p-0">
      <div class="container relative d-flex justify-content-center h-100 p-0">
      <iframe width="100%" height="500px" [src]="'https://www.youtube.com/embed/yZKN1CDgcXQ?si=V1GhXAub-752mabv' | safeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      <p style="position: absolute; left: 25px; bottom: 50px;" class="text-white mat-title bg-black p-1">‘N GEMEENTE VIR ONS GEMEENSKAP</p>
    </div>
    </div>
  </div>
</div>
<div class="container">
<div class="row">
  <div class="col-md-8">

        <h1 class="mat-display-1 text-grey mb-3">
          <b>Neem kennis</b> van belangrike datums
        </h1>

        <div class="col-md-12 mb-4 col-sm-12 col-xs-12 p-0 bg-black">
        <div class="pulse d-flex justify-content-center align-items-center" #rect *ngIf="loading || !belangrikeDatums">
          <mat-spinner color="accent"></mat-spinner>
        </div>
      

      <lightgallery *ngIf="!loading && belangrikeDatums" [settings]="settings" [BeforeSlide]="onBeforeSlide" class="row justify-content-center scrollable p-0 flex-1">
      
        <a *ngFor="let photo of belangrikeDatums"
          class="col-md-4 d-flex flex-column align-items-center justify-content-center photo-container"
          [href]="photo.baseUrl">
          <img class="img-responsive"
          src="{{photo.baseUrl}}" />
        </a>
      
      </lightgallery>
    </div>
    <div class="row mb-3">
      <div class="col-md-12">
        <h1 class="mat-display-1 text-grey mb-3">
          <b>Word deel</b> van Kruin
        </h1>
      </div>
      <app-bedieninge class="d-flex flex-wrap justify-content-around w-100"></app-bedieninge>
    </div>
    <div class="row m-0">
      <div class="col-md-12 p-0">
        <h1 class="mat-display-1 text-grey mb-3">
          <b>Ondersteun</b> ons gemeenskap
        </h1>
      </div>
      <div class="col-md-6 p-0">
        <iframe width="100%" height="100%" [src]="'https://www.youtube.com/embed/hXiZSjoq8Tg?si=PGQTsgKR3-UH_OPf' | safeUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
        <div class="col-md-6 bg-black" >
          <div *ngIf="loading || !ondersteunOnsGemeenskap" class="pulse d-flex justify-content-center align-items-center" #rect>
              <mat-spinner color="accent"></mat-spinner>
          </div>
          <lightgallery *ngIf="!loading && ondersteunOnsGemeenskap" [settings]="settings" [BeforeSlide]="onBeforeSlide" class="row justify-content-center scrollable p-0 flex-1">
          
            <a *ngFor="let photo of ondersteunOnsGemeenskap"
              class="col-md-6 d-flex flex-column align-items-center justify-content-center photo-container"
              [href]="photo.baseUrl">
              <img class="img-responsive"
              src="{{photo.baseUrl}}" />
            </a>
          
          </lightgallery>
        </div>

    </div>
    <div class="row m-0 mt-3 mb-3">
      <div class="col-md-12 p-0">
        <h1 class="mat-display-1 text-grey mb-3">
          <b>Gesels</b> met ons
        </h1>
      </div>
      <div class="row m-0 bg-grey">
        <div class="col-md-6 d-flex justify-content-center flex-column">
          <img class="img-fluid" src="./../../../assets/Kantoorure.jpg"/>
        </div>
        <div class="col-md-6 p-3">
          <app-contact-form></app-contact-form>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4 pr-0">
    <app-info-panel></app-info-panel>
  </div>
</div>

</div>