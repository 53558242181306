
<div class="container">
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>


    <div class="d-flex justify-content-between m-3" *ngIf="!loading">
        <h1>Fotos</h1>
    </div>

            <div *ngIf="albumList?.length > 0" class="row scrollable p-2 mr-2 flex-1">
                <div *ngFor="let a of albumList" class="col-md-4 d-flex justify-content-center mt-3 mb-3" >
                    <mat-card class="p-0 bg-primary-light d-flex flex-column link" (click)="viewPhotos(a.id, a.title, a.coverPhotoBaseUrl)">
                        <mat-card-header class="pt-3 pb-3 d-flex align-items-center bg-light">
                            <mat-card-title>
                                {{a.title}}
                            </mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="m-0 flex-1">
                            <img class="img-fluid link w-100" [src]="a.coverPhotoBaseUrl"/>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>




        </div>