import { Injectable } from '@angular/core';
import { Meal, MealSearchCriteria } from 'backend/interfaces/meal.interface';

import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MealService {

  meals = new BehaviorSubject<Meal[] | null>(null);
  meals$ = this.meals.asObservable();
  mealCount = new BehaviorSubject<number | null>(null);
  mealCount$ = this.mealCount.asObservable();
  openMeal: Meal;
  constructor() {}

  saveMeal(meal: Meal): Promise<Meal> {
    return Parse.Cloud.run('saveMeal', { meal }).then((result) => {
      return result;
    });
  }

  getMeals(displayLimit?: number, page?: number): Promise<Meal[]> {
    return Parse.Cloud.run('getMeals', { displayLimit, page }).then((result) => {
      this.meals.next(result);
    });
  }

  getMealById(id: string): Promise<Meal> {
    return Parse.Cloud.run('getMealById', { id });
  }

  getMealCount(): Promise<number>{
    return Parse.Cloud.run('getMealCount').then((result) => {
      this.mealCount.next(result);
    });;
  }

  deleteMeal(id: string): Promise<any>{
    return Parse.Cloud.run('deleteMeal', { id });
  }

  searchMeal(criteria: MealSearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchMeal', { criteria });
  }

}