<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<div mat-dialog-title class="d-flex">
  <h1>{{ title }}</h1>
  <div class="spacer"></div>
  <div class="initials">
    <fa-icon (click)="toggleCamera()" [icon]="faCamera" class="link"></fa-icon>
  </div>
  <div class="initials ml-2">
    <fa-icon (click)="uploadFile()" [icon]="faUpload" class="link"></fa-icon>
  </div>
  <div class="initials ml-2">
    <fa-icon
      (click)="uploadRecognitionFile()"
      [icon]="faUser"
      class="link"
    ></fa-icon>
  </div>
</div>

<div mat-dialog-content class="text-center d-flex justify-content-between flex-column align-items-center">
  <input
    type="file"
    class="file-input"
    (change)="onFileSelected($event)"
    #fileInput
    style="display: none"
  />
  <!-- <div class="profile-pic-initials xl" > -->
  <app-camera style="margin: auto;" *ngIf="showCamera"
    (capturedImage)="showThumb($event)"
  ></app-camera>
<!-- </div> -->
  <br />
  <!-- <div class="d-flex">
  <app-guage-chart
    #chart
    [colors]="['#3DA5EE', '#E8E8E8']"
    [chartData]="chartData"
    [height]="300"
    [label]="'test'"
    [title]="'Completed'"
    [width]="300"
  ></app-guage-chart>
  <div>
    <div class="d-flex">
      <span>angry</span>
      <span>{{detectionStream.angry}}</span>
    </div>
    <div class="d-flex">
      <span>happy</span>
      <span>{{detectionStream.happy}}</span>
    </div>
    <div class="d-flex">
      <span>disgusted</span>
      <span>{{detectionStream.disgusted}}</span>
    </div>
    <div class="d-flex">
      <span>fearful</span>
      <span>{{detectionStream.fearful}}</span>
    </div>
    <div class="d-flex">
      <span>sad</span>
      <span>{{detectionStream.sad}}</span>
    </div>
    <div class="d-flex">
      <span>surprised</span>
      <span>{{detectionStream.surprised}}</span>
    </div>
    <div class="d-flex">
      <span>neutral</span>
      <span>{{detectionStream.neutral}}</span>
    </div>
  </div>
</div> -->
  <!-- <input #dataInput type="number" [ngModel]="chartData" (ngModelChange)="updateChartData($event)"> -->
  <div class="profile-pic-initials lg">
    <img
      class="text-center margin-auto img-fluid"
      (click)="toggleCamera()"
      src="./../../../assets/user.png"
      #webcamImageDisplay
      alt="webcam image"
    />
  </div>
</div>
<div mat-dialog-actions class="d-flex mt-4">
  <input
    type="file"
    class="file-input"
    (change)="onFileSelected($event)"
    #recognitionFileInput
    style="display: none"
  />
  <button mat-button (click)="onNoClick()">Cancel</button>
  <div class="spacer"></div>
  <button
    mat-raised-button
    [disabled]="!webcamImage && !uploadedImage"
    color="primary"
    (click)="saveProfilePicture()"
    cdkFocusInitial
  >
    Save
  </button>
  <button
    mat-raised-button
    [disabled]="!webcamImage && !uploadedImage"
    color="secondary"
    (click)="uploadToBucket()"
  >
    Add Recognition
  </button>
  <button
    mat-raised-button
    [disabled]="!webcamImage && !uploadedImage"
    color="secondary"
    (click)="recognise()"
  >
    Authenticate Face
  </button>
</div>
