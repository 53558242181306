import { Injectable } from '@angular/core';
import { MealPlan, MealPlanSearchCriteria } from 'backend/interfaces/meal-plan.interface';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MealPlanService {

  mealPlans = new BehaviorSubject<MealPlan[] | null>(null);
  mealPlans$ = this.mealPlans.asObservable();
  activatedMealPlan = new BehaviorSubject<MealPlan>(null);
  activatedMealPlan$ = this.activatedMealPlan.asObservable();
  mealPlanCount = new BehaviorSubject<number | null>(null);
  mealPlanCount$ = this.mealPlanCount.asObservable();
  openMealPlan: MealPlan;
  constructor() {}

  saveMealPlan(mealPlan: MealPlan): Promise<MealPlan> {
    return Parse.Cloud.run('saveMealPlan', { mealPlan }).then((result) => {
      return result;
    });
  }

  getMealPlans(displayLimit?: number, page?: number): Promise<void> {
    return Parse.Cloud.run('getMealPlans', { displayLimit, page }).then((result) => {
      this.mealPlans.next(result);
    });
  }

  getMealPlanById(id: string): Promise<MealPlan> {
    return Parse.Cloud.run('getMealPlanById', { id }).then((result) => {
      console.log(result)
      this.activatedMealPlan.next(result);
    });
  }

  getMealPlanCount(): Promise<number>{
    return Parse.Cloud.run('getMealPlanCount').then((result) => {
      this.mealPlanCount.next(result);
    });;
  }

  deleteMealPlan(id: string): Promise<any>{
    return Parse.Cloud.run('deleteMealPlan', { id });
  }

  searchMealPlan(criteria: MealPlanSearchCriteria): Promise<any>{
    console.log(criteria)
    return Parse.Cloud.run('searchMealPlan', { criteria });
  }

}