<div class="main-wrapper">
  <div class="container">
    <mat-toolbar class="d-flex justify-content-between bg-white mt-3 p-3">
      <div class="desktop">
        <button
          class="m-1 mat-title"
          mat-button
          [matMenuTriggerFor]="kykMenu"
          #kykMenuTrigger="matMenuTrigger"
        >
          KYK
        </button>
        <button
          class="m-1 mat-title"
          mat-button
          [matMenuTriggerFor]="leesMenu"
          #leesMenuTrigger="matMenuTrigger"
        >
          LEES
        </button>
        <button
          class="m-1 mat-title"
          mat-button
          [matMenuTriggerFor]="luisterMenu"
          #luisterMenuTrigger="matMenuTrigger"
        >
          LUISTER
        </button>
        <button
          class="m-1 mat-title"
          mat-button
          [matMenuTriggerFor]="kontakMenu"
          #kontakMenuTrigger="matMenuTrigger"
        >
          KONTAK
        </button>
      </div>
      <mat-menu class="mt-3" #kykMenu="matMenu" [overlapTrigger]="false">
        <span
          class="d-flex flex-column"
          (mouseleave)="kykMenuTrigger.closeMenu()"
        >
          <button class="p-3 link" mat-button>
            <a target="_blank" href="https://www.youtube.com/@kruinkerk786">
              Aanlyn Preke</a
            >
          </button>
          <button
            class="p-3 link"
            mat-button
            routerLink="fotos"
            routerLinkActive="active"
          >
            Fotos
          </button>
        </span>
      </mat-menu>
      <mat-menu class="mt-3" #leesMenu="matMenu" [overlapTrigger]="false">
        <span
          class="d-flex flex-column"
          (mouseleave)="leesMenuTrigger.closeMenu()"
        >
          <button
            class="p-3 link"
            mat-button
            (click)="openDialog()"
          >
            Roeping
          </button>
          <button class="p-3 link" mat-button>
            <a
              href="http://00kt6.mjt.lu/nl3/jCoX4jsktuXa8LHENQrh6w?m=ATwAARfCGsAAAAAAAAAAAe_4jkkAAAAA5LgAAAAAABnaPABnJMt6CljHB0bmTiW3D7pOpaiCeQAY_oA&b=fea403a6&e=951b04b4&x=j6fZ_7sL5P0TxRfn5uTRFqkbO0v6obr1OXAjFcGle6I"
              target="_blank"
              >Nuusbrief</a
            >
          </button>
        </span>
      </mat-menu>
      <mat-menu class="mt-3" #luisterMenu="matMenu" [overlapTrigger]="false">
        <span
          class="d-flex flex-column"
          (mouseleave)="luisterMenuTrigger.closeMenu()"
        >
          <button class="p-3 link" mat-button>
            <a href="https://soundcloud.com/kommunikasie" target="_blank"
              >Podgooi</a
            >
          </button>
        </span>
      </mat-menu>
      <mat-menu class="mt-3" #kontakMenu="matMenu" [overlapTrigger]="false">
        <div
          class="d-flex flex-column p-3"
          (mouseleave)="kontakMenuTrigger.closeMenu()"
        >
          <span class="mat-subtitle text-grey">Adres</span>
          <p>Fiskaalstraat 30,Glen Marais, Kempton Park,1619</p>
          <span class="mat-subtitle text-grey">Kontak Besonderhede</span>
          <p>
            011 391 1343<br /><a href="mailto:admin@kruin-kerk.co.za">{{
              "admin@kruin-kerk.co.za"
            }}</a
            >.
          </p>
          <span class="mat-subtitle text-grey">Webtuiste</span>
          <p>www.kruin-kerk.co.za</p>
          <span class="mat-subtitle text-grey">Nood Nommer</span>
          <p>Koster Anton Minnaar<br />081 757 9783</p>
          <span class="mat-subtitle text-grey">Bankbesonderhede</span>
          <p>
            NG Kempton Kruin<br />
            Absa-bank Tjekrekening<br />
            Rekeningnr 260 580 965<br />
            Verwysing Dankoffer
          </p>
        </div>
      </mat-menu>
      <div class="mobile">
        <button mat-stroked-button color="accent" [matMenuTriggerFor]="menu">
          MENU
        </button>
        <mat-menu #menu="matMenu">
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            target="_blank"
            href="https://www.youtube.com/@kruinkerk786"
          >
            Aanlyn Preke
          </a>
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            routerLink="fotos"
            routerLinkActive="active"
          >
            Fotos
          </a>
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            (click)="openDialog()"
          >
            Roeping
          </a>
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            href="http://00kt6.mjt.lu/nl3/6TFT0OmQJNr6569ZQrmW_w?m=ATwAARTZmQAAAAAAAAAAAe_4jkkAAAAA5LgAAAAAABnaPABnCOIrYKEKACESTZqzQ0YTJ2BVqAAY_oA&b=af25eda6&e=47dc4555&x=j6fZ_7sL5P0TxRfn5uTRFqkbO0v6obr1OXAjFcGle6I"
            target="_blank"
            >Nuusbrief
          </a>
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            href="https://soundcloud.com/kommunikasie"
            target="_blank"
            >Podgooi</a
          >
          <a
            class="p-3 link d-flex align-items-center"
            mat-menu-item
            (click)="Login()"
            >Teken in/Registreer</a
          >
          <div class="p-3">
            <span class="mat-subtitle">ADRES</span>
            <p class="mat-subtitle">
              Fiskaalstraat 30,Glen Marais, Kempton Park,1619
            </p>
            <span class="mat-subtitle">KONTAK BESONDERHEDE</span>
            <p class="mat-subtitle">
              011 391 1343<br /><a href="mailto:admin@kruin-kerk.co.za">{{
                "admin@kruin-kerk.co.za"
              }}</a
              >.
            </p>
            <span class="mat-subtitle">WEBTUISTE</span>
            <p class="mat-subtitle">www.kruin-kerk.co.za</p>
            <span class="mat-subtitle">NOOD NOMMER</span>
            <p class="mat-subtitle">Koster Anton Minnaar<br />081 757 9783</p>
            <span class="mat-subtitle">BANKBESONDERHEDE</span>
            <p class="mat-subtitle">
              NG Kempton Kruin<br />
              Absa-bank Tjekrekening<br />
              Rekeningnr 260 580 965<br />
              Verwysing Dankoffer
            </p>
          </div>
        </mat-menu>
      </div>
      <div class="spacer"></div>
      <div class="desktop">
        <button
          class="bg-black text-white border-radius-30 mat-raised-button"
          (click)="Login()"
        >
          Teken in / Registreer
        </button>
      </div>
      <div class="pl-3 pr-1 desktop">
        <img class="logo-md" src="assets/kruin50.png" />
      </div>
      <div class="pl-2 pr-3">
        <img class="logo-md link" routerLink="home" src="assets/logo.png" />
      </div>
    </mat-toolbar>
  </div>
  <div class="p-0 dashboard-container flex-1">
    <router-outlet></router-outlet>
    <div class="container mt-3">
      <div class="row bg-black p-3">
        <div class="col-md-3">
            <div class="section-title">
                <h3 class="m-0 text-grey mat-title">Vinnige skakels</h3>
              </div>

              <div class="row m-0">
                <div class="col-md-12 p-0">
                    <a
                      class="p-0 link d-flex align-items-center text-white"
                      target="_blank"
                      href="https://www.youtube.com/@kruinkerk786"
                    >
                      Aanlyn Preke
                    </a>
                    <a
                      class="p-0 link d-flex align-items-center text-white"
                      routerLink="fotos"
                      routerLinkActive="active"
                    >
                      Fotos
                    </a>
                    <a
                      class="p-0 link d-flex align-items-center text-white"
                      routerLink="terugvoer"
                      routerLinkActive="active"
                    >
                      Roeping
                    </a>
                    
                      <a
                        class="p-0 link d-flex align-items-center text-white"
                        href="http://00kt6.mjt.lu/nl3/6TFT0OmQJNr6569ZQrmW_w?m=ATwAARTZmQAAAAAAAAAAAe_4jkkAAAAA5LgAAAAAABnaPABnCOIrYKEKACESTZqzQ0YTJ2BVqAAY_oA&b=af25eda6&e=47dc4555&x=j6fZ_7sL5P0TxRfn5uTRFqkbO0v6obr1OXAjFcGle6I"
                        target="_blank"
                        >Nuusbrief
                      </a>
                      <a
                        class="p-0 link d-flex align-items-center text-white"
                        href="https://soundcloud.com/kommunikasie"
                        target="_blank"
                        >Podgooi</a
                      >
                      <a
                        class="p-0 link d-flex align-items-center text-white"
                        (click)="Login()"
                        >Teken in/Registreer</a
                      >
                    </div>
              </div>
        </div>
        <div class="col-md-4">
          <div class="section-title">
            <h3 class="m-0 text-grey mat-title">Vorms en dokumente</h3>
          </div>
          <div class="row m-0">
            <div class="col-md-12 p-0">
          <a
            class="p-0 mb-3 link d-flex align-items-center text-white"
            (click)="
              getFileByIdForDownload(
                'NuweIntrekkers',
                '1-howhTayk5eDAsOqTxrHmdzSZhRjXLim',
                'Word'
              )
            "
          >
            Nuwe Intrekkers <mat-icon size="sm">download</mat-icon>
          </a>
          <a
            class="p-0 mb-3 link d-flex align-items-center text-white"
            (click)="
              getFileByIdForDownload(
                'Aansoek om Doop',
                '1HIc_qVA7Vx0-IYxfP0WAao42naFD33zo',
                'Word'
              )
            "
          >
            Aansoek om Doop <mat-icon size="sm">download</mat-icon>
          </a>
          </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="section-title">
            <h2 class="mat-title m-0 text-grey">Kruin-nuus</h2>
          </div>
          <a
            class="link text-white"
            color="primary"
            mat-raised-button
            href="http://00kt6.mjt.lu/nl3/jCoX4jsktuXa8LHENQrh6w?m=ATwAARfCGsAAAAAAAAAAAe_4jkkAAAAA5LgAAAAAABnaPABnJMt6CljHB0bmTiW3D7pOpaiCeQAY_oA&b=fea403a6&e=951b04b4&x=j6fZ_7sL5P0TxRfn5uTRFqkbO0v6obr1OXAjFcGle6I"
            >Nuusbrief</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
