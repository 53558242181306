import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faBook, faCalendar, faCheckDouble, faChevronDown, faChevronLeft, faChevronRight, faChurch, faList, faPhotoVideo, faPlay, faQuoteLeft, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';
import { StudentService } from 'src/app/admin/services/student.service';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  albumList = [];

  loading = false;
  faNext = faChevronRight;
  faPrev = faChevronLeft;
  faDown = faChevronDown;
  faPhoto = faPhotoVideo;
  faCalendar = faCalendar;
  faQuoteLeft = faQuoteLeft;
  faCheckList = faCheckDouble;
  CalendarView = CalendarView;

  viewDate: Date = new Date();
  minTime = this.viewDate.getHours()-1;
  maxTime = this.viewDate.getHours()+3;
  events: CalendarEvent[] = [
  ];

  timelineEvents = [{
    id: 0,
    description: "20 days ago",
    timestamp: moment().subtract(20, 'days').toDate(),
    title: "Milestone 1"},
    {
      id: 1,
      description: "30 days ago",
      timestamp: moment().subtract(30, 'days').toDate(),
      title: "Milestone 2"},
    {
      id: 2,
      description: "40 days ago",
      timestamp: moment().subtract(40, 'days').toDate(),
      title: "Milestone 3"}];

      settings = {
        counter: false,
        plugins: [lgZoom],
      };
      onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };
  constructor(private dataService: DataService, private router: Router, private studentService: StudentService) {
    setInterval(() => {
      this.viewDate = new Date();
    }, 60000);
  }
  belangrikeDatums = [];
  ondersteunOnsGemeenskap = []
  async ngOnInit(): Promise<void> {
  }

  async ngAfterViewInit(){
    this.loading = true;

    await this.studentService.getAlbumById('AKb-cJTdA6AmWfxWLv5Ef900Px3eQ-DzsfuQsA5onGL6gCXlFEzB3QZhp1jXKipoEhqQHYIG-dgc').then(res => {
      let orderByDescriptionDate;
      orderByDescriptionDate = res.mediaItems.sort((a,b) => {
        const dateA = moment(a.description)
        const dateB = moment(b.description)
        return dateA.diff(dateB)
      })
      this.belangrikeDatums = res.mediaItems;
    });

    await this.studentService.getAlbumById('AKb-cJQrHFCTOM9RsqVooQDujF4zsQ42_1_hAxhAoV-SzDbizXPjJIekGPyzzZQ4oHSSy83-ZYtD').then(res => {
      this.ondersteunOnsGemeenskap = res.mediaItems;
    });
    this.loading = false;
  }
  newAppointment(): void{
    this.router.navigateByUrl('appointment');
  }

  docuMerge(): void{
    this.router.navigateByUrl('pdf-worker');
  }


  
}
