import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Loader } from "@googlemaps/js-api-loader"
import { environment } from 'src/environments/environment';
const loader = new Loader({
  apiKey: environment.gmap,
  version: "weekly",
});

loader.load().then(async () => {
  const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
});
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  latitude: number;
  longitude: number;
  zoom: number;
  apiLoaded: Observable<boolean>;
  address: string;
  private geoCoder;
  loading = true;
  options: google.maps.MapOptions;
  @Input() locations: any;
  @ViewChild('mapElement', {static: false}) mapElement: ElementRef;
  map: google.maps.Map;
  center;
  mapOptions: google.maps.MapOptions;
  infoWindow: google.maps.InfoWindow;
  mapMarkers = [];
  constructor(private httpClient: HttpClient) {
  }
  async ngOnInit(): Promise<void> {
    // this.setCurrentLocation();
    // this.geoCoder = new google.maps.Geocoder;
    // await this.getCurrentLocation();
    // for (let i of this.locations){
    //   if(i.address.length > 10){
    //     const res = await this.getLatLng(i.address);
    //     if(res){
    //       await this.drawMarker(res, i.address);
    //     }
    //   }
    // }
  }

  async getCurrentLocation(): Promise<void>{
    if (navigator) {
    navigator.geolocation.getCurrentPosition(async (position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.mapOptions = {
          center: new google.maps.LatLng({ lat: position.coords.latitude, lng: position.coords.longitude }),
          zoom: 10
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);
        this.infoWindow = new google.maps.InfoWindow();
        this.drawMarker({ lat: position.coords.latitude, lng: position.coords.longitude });
      });
    }
  }
  async getAddress(latitude, longitude): Promise<string> {
    let address = '';
    await this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          address = results[0].formatted_address;
        } else {
          window.alert('No results found');
          
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
    return address;
  }

  async getLatLng(address: string): Promise<{ lat: number, lng: number }> {
    let res;
    await this.geoCoder.geocode({ 'address': address }, (results, status) => {
        if(results){
          res = {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
        }else {
          res = null;
        }
    }).catch(err => console.log(err));
    return res;
  }
  svgMarker = {
    path: "M24 27C31.575 27 37.7143 20.9566 37.7143 13.5C37.7143 6.04336 31.575 0 24 0C16.425 0 10.2857 6.04336 10.2857 13.5C10.2857 20.9566 16.425 27 24 27ZM34.2643 30.4383L29.1429 50.625L25.7143 36.2812L29.1429 30.375H18.8571L22.2857 36.2812L18.8571 50.625L13.7357 30.4383C6.09643 30.7969 0 36.9457 0 44.55V48.9375C0 51.7324 2.30357 54 5.14286 54H42.8571C45.6964 54 48 51.7324 48 48.9375V44.55C48 36.9457 41.9036 30.7969 34.2643 30.4383Z",
    fill: "#000000",
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 0.5,
    anchor: new google.maps.Point(0, 20),
  };
  async drawMarker(location: {lat: number, lng: number}, address?: string): Promise<void> {
    let targetAddress = address ? address : '';
    let icon;
    let label;
    if(targetAddress === ''){
      targetAddress = await this.getAddress(location.lat, location.lng);
      icon = this.svgMarker;
      label = 'You are here';
    }
      const tempMarker = new google.maps.Marker({position: location, map: this.map, icon: icon, label: label});
      tempMarker.addListener('click',(() => {
      this.infoWindow.setContent('<p><b>Longitude</b> : ' + location.lng + '</p><p><b>Latitude</b> : ' + location.lat +'</p>' + '<p><b>Address</b> : ' + targetAddress +'</p>' + '<a target="_blank" href="https://www.google.com/maps/search/'+ location.lat + ',' + location.lng + '">Open in google</a>');
      this.infoWindow.open(this.map, tempMarker);
      }));
      this.mapMarkers.push(tempMarker);
      }

}
// https://www.google.com/maps/search/-26.1743733,28.3111257/@-26.1743733,28.3085454,17z?entry=ttu