import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faEye, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { StudentService } from 'src/app/admin/services/student.service';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  passwordVisible: boolean = false;
  faPhoneVolume = faPhoneVolume;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private studentService: StudentService,
    private authService: AuthService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      id: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { 
      return this.loginForm.controls; 
  }

  clearButton() {
    this.loading = false;
  }

  async onSubmit(): Promise<any> {
    this.loading = true;
    this.submitted = true;

    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    const user = {
      Name: this.f.name.value,
      Surname: this.f.surname.value,
      Mobile: this.f.mobile.value,
      Email: this.f.email.value.toLowerCase(),
      ID: this.f.id.value
    }
    const password = this.f.password.value;
    this.authService.signup(user, password).then(res => {
      if(res.message){
        this.openSnackBar(res.message);
        this.loading = false;
      }else{
      this.openSnackBar('Sukses, ons teken jou in.')
      this.login();
      }
    }).catch(err => alert(err));
  }

  async login() {
    this.loading = true;

    this.authService
        .login(this.loginForm.controls['email'].value.toLowerCase(), this.loginForm.controls['password'].value)
        .then((user) => {
                if (user.get('role') && (user.get('role') === 'admin')) {
                  this.router.navigate(['admin', 'dashboard']);
                } else {
                    this.router.navigate(['member']);
                }
            this.loading = false;
        })
        .catch((err) => {
          this.router.navigate(['auth', 'login']);
            this.loading = false;
        });
}


    togglePassword(show) {
        this.passwordVisible = show;
    }

    openSnackBar(message: string) {
      this._snackBar.open(message, 'OK', {
        duration: 3000
      });
    }
}
