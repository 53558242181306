import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faFilePdf, faImage } from '@fortawesome/free-solid-svg-icons';
import { ParentService } from 'src/app/admin/services/parent.service';

interface DialogData {
  parentId: string;
  files: any[];
  albumId: string;
}
@Component({
  selector: 'app-album-dialog',
  templateUrl: './album-dialog.component.html',
  styleUrls: ['./album-dialog.component.scss']
})
export class AlbumDialogComponent implements OnInit {
  faImage = faImage;
  faFilePdf = faFilePdf;
  selectedFile: {type: string, src: string};
  showcase = false;
  loading = false;
  constructor(public dialogRef: MatDialogRef<AlbumDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private parentService: ParentService) { }
  uploadedImage = {name: '', type: '', source: null};
  ngOnInit(): void {
    console.log(this.data);
  }
  close(): void {
    this.dialogRef.close();
  }

  uploadPhotos(){
    console.log(this.uploadedImage);
    let description = '';
    this.parentService.uploadPhoto(this.data.albumId, this.uploadedImage, description).then(res => {
      console.log(res)
    });
  }

getFile(fileId: string, mimeType: string): Promise<string>{
  this.showcase = true;
  this.loading = true;
  document.getElementById('showcaseImage').setAttribute("src", '');
  document.getElementById('showcaseFile').setAttribute("src", '');
  return this.parentService.getParentFile(fileId, mimeType).then(res => {
    if(mimeType.includes('image')){
      document.getElementById('showcaseImage').setAttribute("src", res);
    }else{
      document.getElementById('showcaseFile').setAttribute("src", res);
    }
    this.selectedFile = {
      type: mimeType.includes('image') ? 'image' : 'file',
      src: res
    }
    this.loading = false;
    return res;
  }).catch(err => console.log(err))

}
  
  async onFileSelected(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
          reader.onload = () => {
              this.uploadedImage = {
                source: reader.result,
                type: file.type,
                name: file.name
              }
          };
          reader.readAsDataURL(file)
    }
  }
}
