<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>

<div mat-dialog-title class="d-flex relative">
    <div>
    <h1>{{data.title}}</h1>
    </div>
    <div class="spacer"></div>
        <div class="initials">
            <fa-icon (click)="toggleCamera()" [icon]="faCamera" class="link"></fa-icon> 
        </div>
        <!-- <div class="initials ml-2">
            <fa-icon (click)="uploadFile()" [icon]="faUpload" class="link"></fa-icon> 
        </div> -->
    </div>
    
    <div mat-dialog-content class="text-center h-100">
        <input
        type="file"
        class="file-input"
        (change)="onFileSelected($event)"
        #fileInput
        style="display: none"
      />
      <br/>
      <div class="h-100 row detection-result justify-content-center f-flex-flex-column">
            <div class="col-md-12">
                <app-camera style="margin: auto;" *ngIf="showCamera" (capturedImage)="showThumb($event)"></app-camera>
                <div class="relative link h-100 d-flex align-items-center justify-content-center" (click)="toggleCamera()">
                    <img class="img-fluid webcamImageDisplay relative" [ngClass]="{'profile-pic-initials lg': recognisedFace}" src="./../../../assets/user.png" #webcamImageDisplay>
                    <!-- <div (click)="toggleCamera()" class="green-scanner" *ngIf="showCamera"></div> -->
                </div>
                
            </div>
            <ng-container *ngIf="!loading && recognisedFace">
                <h1 class="link" (click)="search()">{{recognisedFace}}</h1>
                <div class="children w-100 p-3" *ngIf="children && children.length > 0">
                    <h3>Kinders</h3>
                    <ng-container *ngFor="let child of children">
                    <div class="d-flex align-items-center justify-content-between">
                        <p>{{child.Name + '' + child.Surname}}</p>
                        <div>
                        <button
                        (click)="printLabel(child)"
                        title=""
                        mat-stroked-button
                        type="button"
                        color="primary"
                        class="m-1"
                      >
                        <span>Druk Kaartjie</span>
                      </button>
                      <button
                        (click)="checkin(child.id)"
                        title=""
                        type="button"
                        [disabled]="child.Report?.ReportId"
                        class="btn btn-outline-primary m-1"
                      >
                        <span *ngIf="child.Report">Meld aan</span>
                        <fa-icon
                          *ngIf="child.Report?.ReportId"
                          class="ml-3 text-primary"
                          [icon]="faCheck"
                        ></fa-icon>
                        <mat-spinner
                          style="margin: auto"
                          *ngIf="!child.Report"
                          color="primary"
                          diameter="20"
                        ></mat-spinner>
                      </button>
                      <button
                        (click)="checkout(child.id)"
                        title=""
                        type="button"
                        [disabled]="child.Report?.CheckOutTime"
                        class="btn btn-outline-primary m-1"
                      >
                        <span *ngIf="child.Report">Teken uit</span>
                        <fa-icon
                          *ngIf="child.Report?.CheckOutTime"
                          class="ml-3 text-primary"
                          [icon]="faCheck"
                        ></fa-icon>
                        <mat-spinner
                          style="margin: auto"
                          *ngIf="!child.Report"
                          color="primary"
                          diameter="20"
                        ></mat-spinner>
                      </button>
                    </div>
                    </div>
                    <hr/>
                </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div mat-dialog-actions class="d-flex">
      <input
      type="file"
      class="file-input"
      (change)="onFileSelected($event)"
      #recognitionFileInput
      style="display: none"
    />
        <button mat-button (click)="onNoClick()">Close</button>
        <div class="spacer"></div>
        <button *ngIf="recognisedPerson.name === '' && !loading && !showCamera" mat-stroked-button (click)="toggleCamera()">Retry</button>
        <!-- <button mat-raised-button [disabled]="!webcamImage && !uploadedImage" color="secondary" (click)="recognise()">Identify Face</button> -->
    </div>