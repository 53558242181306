

<div class="container">
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading || !coverPhoto">
    <div>
        <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>

<div class="p-5 m-3 cover-photo relative" [ngStyle]="{'background-image': coverPhoto}">
    <h1 class="bg-50perc-white p-3">{{albumTitle}}</h1>
</div>
<div *ngIf="projectPhotos.length > 0" class="row scrollable p-2 mr-2 flex-1">
    <lightgallery *ngIf="!loading && projectPhotos" [settings]="settings" [BeforeSlide]="onBeforeSlide" class="row justify-content-center scrollable p-2 flex-1">
        <p *ngIf="projectPhotos.length < 1">No photos</p>
      
        <a *ngFor="let photo of projectPhotos"
          class="col-md-3 d-flex flex-column align-items-center justify-content-center photo-container"
          [href]="photo.baseUrl">
          <img class="img-fluid"
          src="{{photo.baseUrl}}" />
        </a>
      
      </lightgallery>
</div>
</div>