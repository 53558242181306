<div class="bg-image-1">
  <div class="d-flex justify-content-between align-items-center">
      <img class="img-fluid logo" src="assets/icon.png"/>
      <button mat-raised-button color="accent" (click)="goHome()">Home</button>
      <div class="spacer"></div>
      <button mat-raised-button color="primary" (click)="continueToSignIn()">Login</button>
      <h1 class="headline mb-0 m-3 text-white">PDF Merger</h1>
  </div>
</div>  
<mat-progress-bar [value]="0">
</mat-progress-bar>
<div class="container">

  <mat-card class="mt-4">
    <mat-card-header>
      <mat-card-title>Add files to merge</mat-card-title>
    </mat-card-header>
    <mat-card-content class="row p-3">
      <form [formGroup]="documentsForm" class="row">
        <div class="col-md-12 mb-3" *ngFor="let control of  documentsFieldAsFormArray?.controls ;let i = index;" formArrayName='documents'>
            <div [formGroupName]="i" >
              <div class="flex-1 d-flex border-primary p-2 align-items-center link" (click)="uploadFile(i)">
                <input
                type="file"
                class="file-input"
                formControlName="document"
                accept=".pdf"
                (change)="onFileSelected($event, i)"
                [id]="'file'+i"
              />
        
                <div class="file-upload flex-1 justify-content-between d-flex">
                  <span class="d-flex align-items-center">{{ documents[i]?.name || "No file uploaded yet." }}</span>

                <div  class="input-field" >
                <!-- <input matInput type="text"
                    placeholder="Document" formControlName="document" > -->
                    <button
                    *ngIf="documents[i]"
                    mat-mini-fab
                    [color]="documents[i]?.name ? 'warn' : 'default'"
                    class="upload-btn"
                    (click)="remove(i)"
                  >
                    <fa-icon [icon]="faTrash" [ngClass]="documents[i]?.name ? 'text-white': 'text-primary'"></fa-icon>
                  </button>
                  <button
                  mat-mini-fab
                  [color]="documents[i]?.name ? 'primary' : 'default'"
                  class="upload-btn ml-3"
                >
                  <fa-icon [icon]="faUpload" [ngClass]="documents[i]?.name ? 'text-white': 'text-primary'"></fa-icon>
                </button>
                </div>
                <!-- <button class="button" type="button" (click)="remove(i)" >Remove</button> -->
              </div>
            </div>
        </div>
      <!-- <button class="button" type="submit" (click)="formValue()" [disabled]="form.invalid">
        Login
      </button> -->
      </div>
    </form>

    </mat-card-content>

    <mat-card-actions class="p-2 d-flex justify-content-between">

  <button mat-raised-button [disabled]="documents.length < 2" (click)="embedPdfPages()">Merge PDF's</button>
  <button
  mat-mini-fab
  color="primary"
  (click)="addControl()"
>
  <fa-icon [icon]="faPlus"></fa-icon>
</button>
    </mat-card-actions>
  </mat-card>
</div>