import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MealService } from 'src/app/admin/services/meal.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';


@Component({
  selector: 'app-search-meal',
  templateUrl: './search-meal.component.html',
  styleUrls: ['./search-meal.component.scss']
})
export class SearchMealComponent implements OnInit {

  loading = false;
  searchMealForm: UntypedFormGroup;
  noResults = false;
  constructor(private fb: UntypedFormBuilder, private mealService: MealService, private sideNavService: SideNavService) { }

  ngOnInit(): void {
      this.searchMealForm = this.fb.group(
        {
          name: ['']
      });
  }
  search(){
    this.noResults = false;
    this.loading = true;
    this.mealService.searchMeal(this.searchMealForm.value).then(res => {
      if(res.length > 0){
        this.mealService.meals.next(res);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }
}
