


      <div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="saving">
        <div>
          <mat-spinner color="accent"></mat-spinner>
        </div>
      </div>
    
      <mat-card class="m-3" *ngIf="!loading">
        <!-- <mat-card-header>
          <mat-card-title class="mt-3">Practitioner Details</mat-card-title>
        </mat-card-header> -->
        <mat-card-content>
            <div class="example-sidenav-content">
          <div *ngIf="loading">
            <mat-spinner color="accent"></mat-spinner>
          </div>


          <div *ngIf="!practitioner?.profilePicture || practitioner?.profilePicture?.source === ''" class="initials text-white" (click)="openProfilePictureDialog()">
            <fa-icon [icon]="faUser" class="text-white link"></fa-icon> 
          </div>
          <div *ngIf="practitioner?.profilePicture && practitioner?.profilePicture?.source !== ''" class="profile-pic-initials" (click)="openProfilePictureDialog(practitioner.profilePicture?.source)">
            <img class="img-fluid" [src]="practitioner.profilePicture.source"/>
          </div>


          <form *ngIf="!loading" class="user" [formGroup]="practitionerForm" (ngSubmit)="savePractitioner()">
            <div class="d-flex row p-3">
          <div class="col-md-6 d-flex flex-column">
              <mat-form-field appearance="outline">
                  <mat-label>Name</mat-label>
                  <input matInput placeholder="Name" name="name" formControlName="name">
                  <mat-error *ngIf="practitionerForm.controls.name.invalid">Name is Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Surname</mat-label>
                  <input matInput placeholder="Surname" formControlName="surname">
                  <mat-error *ngIf="practitionerForm.controls.surname.invalid">Surname is Required</mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="practitionerForm.controls.idNumber" appearance="outline">
                  <mat-label>ID Number</mat-label>
                  <input matInput placeholder="ID" formControlName="idNumber">
                  <mat-error *ngIf="practitionerForm.controls.idNumber.invalid">ID number is Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Occupation</mat-label>
                  <input matInput placeholder="Occupation" formControlName="occupation">
                  <mat-error *ngIf="practitionerForm.controls.occupation.invalid">Occupation is Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input matInput placeholder="Title" formControlName="title">
                <mat-error *ngIf="practitionerForm.controls.title.invalid">Title is Required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Gender</mat-label>
                <input matInput placeholder="Gender" formControlName="gender">
                <mat-error *ngIf="practitionerForm.controls.gender.invalid">Gender is Required</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6 d-flex flex-column">
              <mat-form-field appearance="outline">
                  <mat-label>Address</mat-label>
                  <input matInput placeholder="Address" formControlName="address">
                  <mat-error *ngIf="practitionerForm.controls.address.invalid">Address is Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Mobile</mat-label>
                  <input matInput placeholder="Mobile" formControlName="mobile">
                  <mat-error *ngIf="practitionerForm.controls.mobile.invalid">Mobile is Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="Email" formControlName="email">
                  <mat-error *ngIf="practitionerForm.controls.email.invalid">Email is Required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="fill" class="w-100">
                <mat-label>Class</mat-label>
                <mat-select formControlName="group" name="group">
                  <mat-option
                    *ngFor="let group of groups"
                    [value]="group"
                    >{{ group.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <div class="col-md-5 d-flex flex-column">
                <div *ngFor="let location of locationsArray" class="d-flex justify-content-between">
                  <div><fa-icon class="mr-2" [icon]="faMapMarker"></fa-icon>{{location.name}}</div>
                </div>
                <button type="button" mat-button color="accent" aria-label="Search Location"  (click)="openDialog()">
                  Add Location
                </button>
              </div>
          </div>
        </div>
        <mat-toolbar class="d-flex justify-content-between">
          <button type="submit" [disabled]="!practitionerForm.valid || saving" mat-raised-button color="primary">
              Save
          </button>
        </mat-toolbar>
        </form>







            <!-- <form *ngIf="!loading" class="user" [formGroup]="practitionerForm" (ngSubmit)="savePractitioner()">
            <div class="row d-flex p-3">
            
              <div class="col-md-6 d-flex flex-column">
                  <mat-form-field appearance="outline">
                      <mat-label>Name</mat-label>
                      <input matInput placeholder="name" formControlName="name">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                      <mat-label>Surname</mat-label>
                      <input matInput placeholder="address" formControlName="surname">
                  </mat-form-field>
              </div>
              <div class="col-md-5 d-flex flex-column">
                <div *ngFor="let location of locationsArray" class="d-flex justify-content-between">
                  <div><fa-icon class="mr-2" [icon]="faMapMarker"></fa-icon>{{location.name}}</div>
                </div>
                <button type="button" mat-button color="accent" aria-label="Search Location"  (click)="openDialog()">
                  Add Location
                </button>
              </div>
            </div>
            <mat-toolbar class="d-flex justify-content-between">
                <button type="submit" [disabled]="!practitionerForm.valid || saving" mat-raised-button color="primary">
                    Save
                </button>
              </mat-toolbar>
            </form> -->
          </div>
        </mat-card-content>
      </mat-card>

      <ng-template #dialogTemplate>
        <h2 mat-dialog-title>Select Locations to add</h2>
        <mat-dialog-content class="mat-typography">
        

          <div class="d-flex justify-content-between p-1 mb-1" *ngFor="let location of locations">
            <div><fa-icon class="mr-2" [icon]="faMapMarker"></fa-icon>{{location.name}}</div>
            <button type="button" mat-button mat-mini-fab color="primary" aria-label="Search Location"  (click)="addLocationToPractitioner(location, index)">
              <fa-icon class="text-white" [icon]="faPlus"></fa-icon>
            </button>
          </div>

        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-button mat-dialog-close>Done</button>
        </mat-dialog-actions>
      </ng-template>