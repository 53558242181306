import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { faAngry, faPlus, faSadTear, faSmile, faSmileBeam, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DailyReport, Student } from 'backend/interfaces/student.interface';
import { StudentService } from 'src/app/admin/services/student.service';
import * as moment from 'moment';
@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss']
})
export class CheckInComponent implements OnInit {
  loading = false;
  student: Student;
  faPlus = faPlus;
  faTrash = faTrash;
  faNeutral = faAngry;
  faSad = faSadTear;
  faHappy = faSmile;
  date = new Date();
  diaperChangesArray = [];
  medicationArray = [];
  reportId;
  checkInForm = this.fb.group({
    CheckInMood: [''],
    CheckInNotes: [''],
    CheckIn: ['']
  })
  constructor(private fb: FormBuilder, private studentService: StudentService) { }

  async ngOnInit() {
    this.loading = true;
    this.studentService.activatedStudent$.subscribe(async s => {
      return this.student = s;
    });
    await this.studentService.getStudentDailyReport(this.student.id, this.date.toDateString(), this.date.toDateString()).then(res => {
      this.reportId = res.ReportId;
      this.patchReportForm(res);
    })
    this.loading = false
  }

  saveReport(){
    this.loading = true;
    this.studentService.saveDailyReport({
      ReportId: this.reportId,
      Date: this.date.toString(),
      StudentID: this.student.ID,
      CheckInNotes: this.checkInForm.controls['CheckInNotes'].value,
      CheckInMood: this.checkInForm.controls['CheckInMood'].value,
      CheckIn: this.checkInForm.controls['CheckIn'].value
    }).then(async res => {
      await this.patchReportForm(res);
      this.loading = false;

    });
  }

  setMood(CheckInMood: string){
    this.checkInForm.patchValue({CheckInMood});
  }

  setPresence(CheckIn: string){
    this.checkInForm.patchValue({CheckIn});
  }

  async patchReportForm(reportObject: DailyReport){
    this.loading = true;
    await this.checkInForm.patchValue({
      CheckInMood: reportObject.CheckInMood,
      CheckInNotes: reportObject.CheckInNotes,
      CheckIn: reportObject.CheckIn,
    });
    console.log(this.checkInForm)
    this.loading = false;
}

async selectDate(event: any) {
  this.loading = true;
    this.date = event.value;
    await this.studentService.getStudentDailyReport(this.student.id, this.date.toDateString(), this.date.toDateString()).then(res => {
      if(res.ReportId){
        this.reportId = res.ReportId;
        this.patchReportForm(res);
      }else{
        this.checkInForm.reset();
      }
    })
    this.loading = false;
  }
}