import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    email: string;
    code: string;
    new_password: string;
    loader: boolean;
    showSubmit: boolean = false;
    errorMessage: string;
    faPhoneVolume = faPhoneVolume;
    constructor(private authService: AuthService, private router: Router, private activatedroute: ActivatedRoute) {}

    ngOnInit(): void {
        localStorage.clear();
        const email = this.activatedroute.snapshot.queryParams.email;
        const code = this.activatedroute.snapshot.queryParams.code;
        if (email && code) {
            this.showSubmit = true;
            this.email = email;
            this.code = code;
        }
    }

    forgotPassword() {
        this.loader = true;
        this.showSubmit = true;
        this.authService
            .forgotPassword(this.email)
            .then(async (data) => {
                this.email = '';
                this.errorMessage = 'You will receive an email shortly';
                this.loader = false;
            })
            .catch((error) => {
                this.loader = false;
                this.errorMessage = error.message;
            });
    }
}
