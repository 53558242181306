import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'public-bedieninge',
  templateUrl: './bedieninge.component.html',
  styleUrls: ['./bedieninge.component.scss']
})
export class BedieningeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
