import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { ParseClasses } from '../../../backend/enum/ParseClasses.enum';
import { ParsePointer } from '../../../backend/interfaces/ParsePointer';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  currentUser;
  showChat = new BehaviorSubject<boolean>(null);
  showChat$ = this.showChat.asObservable();
  constructor() {
    
   }

  createChatSpace(name: string): Promise<any> {
    return Parse.Cloud.run('createChatSpace', { name });
  }

  createChatMessage(spaceName: string, message: string): Promise<any> {
    return Parse.Cloud.run('createChatMessage', { spaceName, message });
  }

  sendMessage(message: string, recipient: string, conversationId: string): Promise<any> {
    // let Message = Parse.Object.extend(ParseClasses.MESSAGES);
    // let msg = new Message();
    // msg.set('Sender', Parse.User.current());
    // msg.set('Message', text);
    // msg.save()
    console.log(recipient)
    return Parse.Cloud.run('createChatMessage', { message, recipient, conversationId});
  }

}
