<div class="relative">
  <div
  class="d-flex justify-content-center chat-loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>
<mat-progress-bar *ngIf="!loading" class="mt-3 mb-3 progress-bar
" [mode]="sending ? 'indeterminate' : 'determinate'" [value]="0">
</mat-progress-bar>
<div class="chat">

  <div class="flex-1 d-flex mb-2" *ngFor="let message of messages" [ngClass]="
  message.get('Sender').get('Name') === currentUser
    ? 'align-items-end'
    : 'align-items-start'
">
    <div
      class="message pb-4"
      [ngClass]="
        message.get('Sender').get('Name') === currentUser
          ? 'sender'
          : 'recipient'
      "
    >
      <div>{{ message.get("Message") }}</div>
      <div class="date-time text-muted">
        {{ message.get("updatedAt") | date : "dd MMM YY - HH:MM" }} {{ message.get("read") ? 'read' : 'unread'}}
      </div>
      
    </div>
    </div>
  <mat-card-footer
    class="d-flex pt-3 m-0"
    style="
      position: sticky !important;
      bottom: 0px !important;
      background: white !important;
    "
  >
  <form [formGroup]="messageForm" (submit)="send()" class="d-flex w-100">
    <mat-form-field *ngIf="conversationId !== '' && !loading" class="flex-1 mr-3">
      <input
      #messageInput
        formControlName="message"
        matInput
        placeholder="Message"
      />
    </mat-form-field>
    <!-- <button mat-raised-button (click)="createChat()">Create Chat</button> -->

    <button type="submit" *ngIf="conversationId !== '' && !loading" [disabled]="messageForm.controls['message'].value?.length < 1 || sending" mat-mini-fab>
      <fa-icon class="text-white" [icon]="faPaperPlane"></fa-icon>
    </button>
    <button type="button" *ngIf="conversationId === '' && !loading" mat-raised-button color="accent" (click)="startConversation()">
      Start conversation
    </button>
    </form>

  </mat-card-footer>
</div>
</div>