import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faDownload, faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Student, StudentConsent } from 'backend/interfaces/student.interface';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { FileUpload } from 'backend/interfaces/file-upload';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as Parse from 'parse';
import { Member } from 'backend/interfaces/member.interface';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { StudentService } from 'src/app/admin/services/student.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-student-consent-form',
  templateUrl: './student-consent-form.component.html',
  styleUrls: ['./student-consent-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StudentConsentFormComponent implements OnInit, AfterViewInit {
  consentForm: UntypedFormGroup;
  faPhone = faPhone;
  faHome = faHome;
  faDownload = faDownload;
  faEnvelope = faEnvelope;
  signature: any;
  signed = false;
  pdf: FileUpload;
  saving = false;
  consent;
  currentUser;
  @ViewChild('signatureElement', {static: false}) signatureElement: ElementRef;
  @ViewChild('TreatmentConsent', {static: false}) TreatmentConsent: ElementRef;
  @ViewChild('ConsentContainer', {static: false}) ConsentContainer: ElementRef;
  @Input() student?: Student;
  @Input() parent?: Member;
  @Output() emitStudent = new EventEmitter<Object>();
  @Output() emitBack = new EventEmitter<Boolean>();
  panelOpenState = false;
  company = {
    name: 'Kruin',
    practitioner: 'Name Surname',
    prNo: '',
    address: {
      number: '',
      street: '',
      suburb: '',
      city: '',
      code: ''
    },
    contactNumber: '',
    email: ''
  }
  parentNameAndSurname;
  constructor(private authService: AuthService, private fb: UntypedFormBuilder, public dialog: MatDialog, private studentService: StudentService, private _snackBar: MatSnackBar) { }

async ngOnInit(): Promise<void> {
  this.authService.$User.subscribe((res) => {
    this.currentUser = res;
    if(!this.parent){
      this.parent = {
        Name: this.currentUser.get('Name'),
        Surname: this.currentUser.get('Surname'),
        Mobile: this.currentUser.get('Mobile'),
        Email: this.currentUser.get('username'),
        ID: this.currentUser.get('ID')
      }
    }
  })
  this.saving = true;
  Parse.Cloud.run('getMemberConsent').then(async res => {
    if(res){
      let consentText = res.get('memberConsent');
      this.studentService.activatedStudent$.subscribe(async res => {
        this.student = await res;
        const studentNameAndSurname = this.student.Name + ' ' + this.student.Surname;
        this.parentNameAndSurname = this.parent?.Name + ' ' + this.parent?.Surname;
  
        const mapObj = {
          naamVanKind: studentNameAndSurname,
          naamVanOuer: this.parentNameAndSurname,
        };
        consentText = consentText.replace(/\b(?:naamVanKind|naamVanOuer)\b/gi, matched => mapObj[matched]);
        this.consent = await consentText;
      });
    }
    this.saving = false;
  });
  this.consentForm = this.fb.group({
  InformedConsent: [this.student?.InformedConsent, Validators.required],
  Document: [this.student ? this.student?.Document: {source: '', name: ''}, Validators.required]});
}
ngAfterViewInit(): void {
}

async createStudentConsent(): Promise<void>{
  this.saving = true;
  await this.generatePDF();
  setTimeout(() => {
    this.saveStudentConsent({
      email: this.currentUser.get('username'),
      id: this.student.id,
      ID: this.student.ID,
      InformedConsent: this.consentForm.controls['InformedConsent'].value,
      Document: this.consentForm.controls['Document'].value
    }, this.signature);
  },3500)
}

async saveStudentConsent(student: StudentConsent, signature: string ) {
  this.saving = true;
  this.studentService
    .saveStudentConsent(student)
    .then(() => {
      this.openSnackBar("Vrywaring gestoor. U sal per epos 'n kopie ontvang");
      this.saving = false;
      this.back();
    })
    .catch((err) => {
      this.openSnackBar(err);
      this.saving = false;
    });
}


openSnackBar(message: string) {
  this._snackBar.open(message, 'OK', {
    duration: 3000
  });
}

openDialog(): void {
  const dialogRef = this.dialog.open(SignaturePadComponent, {
    data: {name: this.parent?.Name, surname: this.parent?.Surname},
    width: '80%',
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result){
      this.signature = result.data;
      this.signatureElement.nativeElement.setAttribute('src', this.signature);
      this.signed = true;
      this.createStudentConsent();
    } else {
      this.signed = false;
    }
  });
}

getErrorMessage() {
  if (this.consentForm.controls['Name'].hasError('required')) {
    return 'You must enter a value';
  }

  return this.consentForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
}


  async generatePDF(action?: string): Promise<void> {
    console.log(this.ConsentContainer.nativeElement)
    let docDefinition = {
      content: [
        {
          columns: [
            [
              {
                margin: [-40, 0, 0, 0],
                width: 150,
                alignment : 'center',
                image: await this.getBase64ImageFromURL('../../../assets/icon_kinders.png')
              }
            ]
          ]
        },
        {
          margin: [0,10,0,5],
          columns: [
            {
              text: 'Student',
              fontSize: 10,
              bold: true,
            },
            {
              text: 'Ouer/Voog',
              fontSize: 10,
              bold: true,
            }
          ]
        },
        {
          columns: [
          {
            // Student Particulars
            fontSize: 10,
            stack: [
              {
                columns: [
                  {
                    text: 'Name: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.student.Name, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Surname: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.student.Surname, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'ID Number: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.student.ID, margin: [0,1], width: 'auto'
                  }
                ]
              }
            ],
          },
          {
            // Medical Aid Particulars
            fontSize: 10,
            stack: [
              {
                columns: [
                  {
                    text: 'Naam: ', margin: [0,1], width: '50%'
                  },
                  {
                    text: this.parent?.Name, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Van: ', margin: [0,1], width: '50%'
                  },
                  {
                    text: this.parent?.Surname, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Kontak nommer:', margin: [0,1], width: '50%'
                  },
                  {
                    text: this.parent?.Mobile, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Epos: ', margin: [0,1], width: '50%'
                  },
                  {
                    text: this.parent?.Email, margin: [0,1], width: 'auto'
                  }
                ]
              }
            ],
          }
        ]},
        {
          text: 'Vrywaring',
          fontSize: 10,
          margin: [0, 10, 0, 0],
          bold: true
        },
        {

              stack: [
                {
                  text: this.ConsentContainer.nativeElement.innerText, 
                  margin: [0,5]
                },
              ],
              fontSize: 10

        },
        { 
          image: this.signature,
          fit: [80,80],
          style: 'sectionHeader'
        },
        {
          text: "Handtekening van Ouer/Voog"
        }
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0,15,0,15]          
        }
      }
    };
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    await pdfDocGenerator.getBase64((data) => {
      const pdf = {
        name: this.student.ID+'_studentConsent'+'.pdf',
        source: data
      };
      setTimeout(() => {
        this.consentForm.controls['Document'].setValue(pdf);
      }, 500);
    })
    if(action==='view'){
      pdfDocGenerator.open(); 
    }
  }


  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  back(){
    this.emitBack.emit(true);
  }
  download() {
    const linkSource = this.student.Document;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource.source;
    downloadLink.target = '_blank';
    downloadLink.download = linkSource.name;
    downloadLink.click();
  }
}