import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LocationService } from 'src/app/admin/services/location.service';
import { StudentService } from 'src/app/admin/services/student.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';


@Component({
  selector: 'app-search-location',
  templateUrl: './search-location.component.html',
  styleUrls: ['./search-location.component.scss']
})
export class SearchLocationComponent implements OnInit {

  loading = false;
  searchLocationForm: UntypedFormGroup;
  noResults = false;
  constructor(private fb: UntypedFormBuilder, private locationService: LocationService, private sideNavService: SideNavService) { }

  ngOnInit(): void {
      this.searchLocationForm = this.fb.group(
        {
          name: [''],
          address: [''],
          coordinates: ['']
      });
  }
  search(){
    this.noResults = false;
    this.loading = true;
    this.locationService.searchLocation(this.searchLocationForm.value).then(res => {
      if(res.length > 0){
        this.locationService.locations.next(res);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }
}
