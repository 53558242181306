import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Meal } from 'backend/interfaces/meal.interface';
import { MealService } from 'src/app/admin/services/meal.service';

export enum Allergies {
  MILK = 'Milk',
  WHEAT = 'Wheat',
  EGG = 'Egg'

}
@Component({
  selector: 'app-meal',
  templateUrl: './meal.component.html',
  styleUrls: ['./meal.component.scss']
})
export class MealComponent implements OnInit {
  loading = false;
  faPlus = faPlus;
  faTrash = faTrash;
  faUpload = faUpload;
  photo = './../../../assets/placeholder_food.png';
  meal: Meal = {
    id: '',
    title: '',
    ingredients: [{title: ''}],
    instructions: '',
    photo: {name: '', source: ''}
  };
  mealForm: FormGroup;
  uploadedImage: {name: string, source: string | ArrayBuffer};
  @ViewChild('fileInput') fileInput: ElementRef;

  @ViewChild('imagePreview', { read: ElementRef }) imagePreview: ElementRef;
  constructor(private fb: FormBuilder, private mealService: MealService, private _snackBar: MatSnackBar, private ar: ActivatedRoute, private router: Router){

  }


  async ngOnInit() {
    this.loading = true;
      if(this.ar.snapshot.params['id']){
        const mealId = this.ar.snapshot.params['id'];
        await this.mealService.getMealById(mealId).then(res => {
          this.meal = res;
        });
      }

      this.mealForm = this.fb.group({
        title: this.meal?.title ? this.meal.title : '',
        ingredients: this.meal?.ingredients ? this.fb.array(this.meal.ingredients.map(r => this.fb.group(r))) : this.fb.array([]),
        instructions: this.meal?.instructions ? this.meal.instructions : '',
        photo: ''
      })
      this.loading = false;
      if(this.meal.photo.source){
        this.photo = this.meal.photo.source;
      }
  }

  get ingredients() {
    return this.mealForm.controls["ingredients"] as FormArray;
  }
 

  addIngredient(ingredients: FormArray) {
    const ingredientForm = this.fb.group({
        title: ['']
    });
    this.ingredients.push(ingredientForm);
  }
  removeIngredient(index: number) {
    this.ingredients.removeAt(index);
  }

  uploadFile(){
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  async saveMeal(){
    this.loading = true;
    let updatedMeal;
    if(this.meal.id){
      updatedMeal = {...this.mealForm.value, id: this.meal.id};
    }else{
      updatedMeal = this.mealForm.value;
    }
    if(this.uploadedImage?.name){
      updatedMeal.photo = this.uploadedImage
    }
    this.fileInput.nativeElement.value = null;
    this.mealForm.controls['photo'].setValue('');
    this.mealService.saveMeal(updatedMeal).then(res => {
      if(res){
        this.photo = res.photo.source;
      }
      this.mealService.getMeals();
      this.router.navigate(['admin', 'meal', res.id])
      this.loading = false;
    })
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  async deleteMeal(){
    this.loading = true;
    this.mealService.deleteMeal(this.meal.id).then(res => {
      this.mealService.getMeals(10, 0).then(res => {
        this.router.navigate(['admin', 'meals']);
        this.loading = false;
      })
    });
  }

  async onFileSelected(event): Promise<void>{
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.type.includes('image')) {
          this.imagePreview.nativeElement.src = reader.result;
          this.uploadedImage = {
            source: reader.result as any,
            name: file.name
          }
        }
      };
    }
  }
}
