import { Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReadMoreDialogComponent } from '../read-more-dialog/read-more-dialog.component';

@Component({
  selector: 'app-bedieninge',
  templateUrl: './bedieninge.component.html',
  styleUrls: ['./bedieninge.component.scss']
})
export class BedieningeComponent implements OnInit {
  groups = [
    {
      title: 'Bedieninge',
      description: '',
      url: '',
      icon: '../../../assets/icon_bedien.png',
      video: 'https://www.youtube.com/embed/FLZ7b8dIDbg?si=oPrTNJmebplVVU5U'
    },
    {
      title: 'Belangegroepe',
      description: '',
      url: '',
      icon: '../../../assets/icon_belangegroepe.png',
      video: 'https://www.youtube.com/embed/asFRLKT4BMI?si=BjRsDTnfNNJgA51F'
    },
    {
      title: 'Groei Groepe',
      description: '',
      url: '',
      icon: '../../../assets/icon_groeigroepe.png',
      video: 'https://www.youtube.com/embed/U42oiaxshrE?si=pyFT5IVBaqZtTIYX'
    },
    {
      title: 'Lewens groei',
      description: '',
      url: '',
      icon: '../../../assets/icon_lewensgroei.png',
      video: 'https://www.youtube.com/embed/g08VO95iuvE?si=9l4Xv1wE2gQ-gYYS'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

  readonly dialog = inject(MatDialog);

  openDialog(group: any) {
    const dialogRef = this.dialog.open(ReadMoreDialogComponent, {
      data: group,
      width: '800px',
  });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
