import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faFilePdf, faImage } from '@fortawesome/free-solid-svg-icons';
import { StudentService } from 'src/app/admin/services/student.service';
import * as Parse from 'parse';
interface DialogData {
  name: string;
  mimeType: string;
  file: string;
  fileId: string;
}
@Component({
  selector: 'app-file-viewer-dialog',
  templateUrl: './file-viewer-dialog.component.html',
  styleUrls: ['./file-viewer-dialog.component.scss']
})
export class FileViewerDialogComponent implements OnInit {
  faImage = faImage;
  faFilePdf = faFilePdf;
  selectedFile: {type: string, src: string};
  showcase = false;
  loading = false;
  isFile = true;
  constructor(public dialogRef: MatDialogRef<FileViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private studentService: StudentService, private _snackBar: MatSnackBar) { }
  // uploadedFile = {name: '', type: '', source: null};
  async ngOnInit(): Promise<void> {
    // if(this.data.mimeType.includes('image')){
    //   this.isFile = false;
    //   document.getElementById('showcaseImage').setAttribute("src", this.data.file);
    //   document.getElementById('showcaseFile').setAttribute("style", "display: none")
    // }else{
    // const authToken = await Parse.Cloud.run('getGToken', { });
    
      // return this.http.post(
      //       'https://photoslibrary.googleapis.com/v1/mediaItems:batchCreate',
      //       { albumId: id, newMediaItems }, {headers: batchCreateHeaders}).toPromise();
    // const options = {
    //   url: 'https://www.googleapis.com/drive/v3/files/'+this.data.fileId,
    //   headers: {Authorization: 'Bearer '+ authToken}
    // };
    // const response: HttpResponse = await CapacitorHttp.get(options);
    var dataBlob = this.b64toBlob(this.data.file, this.data.mimeType);


      this.isFile = true;
      document.getElementById('showcaseFile').setAttribute("src", URL.createObjectURL(dataBlob));
      document.getElementById('showcaseImage').setAttribute("style", "display: none")
    } 
    // if(this.data.type === 'photos'){
    //   this.studentService.getAlbumById(this.data.albumId).then(res => {
    //     this.data.files = res.mediaItems;
    //   });
    // }
  // }
  close(): void {
    this.dialogRef.close();
  }

//   uploadFile(){
//     let description = '';
//     this.studentService.uploadFile(this.data.albumId, this.uploadedFile, description).then(res => {
//       console.log(res)
//     });
//   }

//   uploadPhoto(){
//     let description = '';
//     this.loading = true;
//     this.studentService.uploadPhoto(this.data.albumId, this.uploadedFile, description).then(res => {
//       this.openSnackBar(res.newMediaItemResults[0].status.message);
//       this.close();
//     }).catch(err => err);
//   }

// getFile(fileId: string, mimeType: string): Promise<string>{
//   this.showcase = true;
//   this.loading = true;
//   document.getElementById('showcaseImage').setAttribute("src", '');
//   document.getElementById('showcaseFile').setAttribute("src", '');
//   return this.studentService.getStudentFile(fileId, mimeType).then(res => {
//     if(mimeType.includes('image')){
//       document.getElementById('showcaseImage').setAttribute("src", res);
//     }else{
//       document.getElementById('showcaseFile').setAttribute("src", res);
//     }
//     this.selectedFile = {
//       type: mimeType.includes('image') ? 'image' : 'file',
//       src: res
//     }
//     this.loading = false;
//     return res;
//   }).catch(err => console.log(err))

// }
  
//   async onFileSelected(event): Promise<void>{
//     let reader = new FileReader();
//     if (event.target.files && event.target.files.length) {
//       const file: File = event.target.files[0];
//           reader.onload = () => {
//               this.uploadedFile = {
//                 source: reader.result,
//                 type: file.type,
//                 name: file.name
//               }
//           };
//           reader.readAsDataURL(file)
//     }
//   }

//   async onPhotoSelected(event): Promise<void>{
//     let reader = new FileReader();
//     if (event.target.files && event.target.files.length) {
//       const file: File = event.target.files[0];
//       reader.readAsArrayBuffer(file);
//       console.log(file);
//           reader.onload = (e) => {
//               this.uploadedFile = {
//                 source: reader.result,
//                 type: file.type,
//                 name: file.name
//               }
//           };
//           // this.uploadedFile.source = new Int8Array(this.uploadedFile.source as ArrayBuffer);
//     }
//   }


//   openSnackBar(message: string) {
//     this._snackBar.open(message, 'OK', {
//       duration: 3000
//     });
//   }

b64toBlob(b64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 512;
  
    //decode base64 data
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
  
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
  
        var byteArray = new Uint8Array(byteNumbers);
  
        byteArrays.push(byteArray);
    }
  
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
}
