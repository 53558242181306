
<mat-progress-bar
    [mode]="loading ? 'indeterminate' : 'determinate'"
    [value]="0">
</mat-progress-bar>
<form *ngIf="searchStudentForm" [formGroup]="searchStudentForm" (submit)="search()">
    <div class="d-flex row p-3">
    <div class="col-md-12 d-flex flex-column">
        <mat-form-field appearance="outline">
            <mat-label>Naam</mat-label>
            <input (focusout)="trigger($event, 'name')" matInput placeholder="Leerder naam" name="name" formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Van</mat-label>
            <input matInput placeholder="Leerder van" name="surname" formControlName="surname">
        </mat-form-field>
        <button *ngIf="searchPhrase && (searchPhrase?.name !== '' || searchPhrase?.surname !== '')" mat-stroked-button (click)="clearSearch()">
            <fa-layers size="2x">
              <fa-icon class="link" [icon]="faSearch"></fa-icon>
              <fa-layers-counter [content]="'X'"></fa-layers-counter>
            </fa-layers>
            Clear Search
          </button>
    </div>
    </div>
    <div mat-toolbar class="d-flex justify-content-between p-3">
        {{noResults ? 'No Results Found' : ''}}
        <div class="spacer"></div>
        <button mat-raised-button color="accent" [disabled]="loading" type="submit" class="btn btn-outline-secondary">
            {{ loading ? '...' : 'Soek' }}
        </button>
    </div>
</form>
