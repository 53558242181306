
<mat-card class="m-0 chat-container pt-0">
    <mat-card-header class="bg-white">
        <div class="d-flex justify-content-between" *ngIf="!conversation">
            <div>
                <mat-card-title>
                    Chat
                </mat-card-title>
            </div>
            <div></div>
            <button (click)="close()" mat-mini-fab color="default"><fa-icon [icon]="faClose"></fa-icon></button>
        </div>
<div *ngIf="conversation" class="d-flex justify-content-between relative">
    <div
    class="d-flex justify-content-center chat-loader flex-column align-items-center"
    *ngIf="loading"
  >
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
    <div class="d-flex flex-wrap align-items-center">
        <div *ngIf="!recipient.profilePicture || recipient.profilePicture?.source === ''" class="initials text-white bg-dark">
          <fa-icon [icon]="faUser" class="text-white link"></fa-icon> 
        </div>
        <div *ngIf="recipient.profilePicture && recipient.profilePicture?.source !== ''" class="profile-pic-initials sm">
          <img class="img-fluid" [src]="recipient.profilePicture.source"/>
        </div>
        <div class="ml-2">
          <h4 class="m-0">{{recipient.get('Name')}} {{recipient.get('Surname')}}</h4>
        </div>
      </div>
      <div></div>
      <button (click)="closeConversation()" mat-mini-fab color="default"><fa-icon [icon]="faClose"></fa-icon></button>
</div>
    </mat-card-header>
    <mat-card-content *ngIf="!conversation" class="scrollable">
        <div *ngFor="let t of contacts" class="mb-3 p-3 bg-light border-primary link" (click)="openConversation(t)">
                <div class="d-flex flex-wrap align-items-center">
                    <div class="initials text-white bg-dark">
                      <fa-icon [icon]="faUser" class="text-white link"></fa-icon> 
                    </div>
                    <div class="ml-2 flex-1">
                      <h4 class="m-0">{{t.get('Name')}} {{t.get('Surname')}}</h4>
                      <p *ngIf="t" class="m-0">{{t.get('email')}}</p>
                    </div>
                    <div>
                        <fa-icon [icon]="faChevronRight" class="link"></fa-icon> 
                    </div>
                  </div>
        </div>
    </mat-card-content>
    <mat-card-content class="chat" *ngIf="conversation">
    <app-conversation [recipient]="recipient"></app-conversation>
    </mat-card-content>
</mat-card>