import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationService } from 'src/app/admin/services/location.service';
import { Location } from 'backend/interfaces/location.interface';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  saving = false;
  faTrash = faTrash;
  @Input() location: Location = {name: '', address: '', coordinates: ''};
  @Output() emitLocation: EventEmitter<any> = new EventEmitter();
  @Output() closeLocation: EventEmitter<any> = new EventEmitter();
  locationForm: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder, private locationService: LocationService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.locationForm = this.fb.group({
      name: [this.location ? this.location.name : '', Validators.required],
      address: [this.location ? this.location.address : '', Validators.required],
      coordinates: [this.location ? this.location.coordinates : ''],
      colorId: [this.location ? this.location.colorId : 1]
    })
  }

  async saveLocation(){
    if(this.locationForm.dirty){
      this.saving = true;
      let updatedLocation;
      if(this.location){
        updatedLocation = {...this.locationForm.value, id: this.location.id}
      }else{
        updatedLocation = { ...this.locationForm.value }
      }
    
      this.locationService
      .saveLocation(updatedLocation)
      .then((res) => {
        this.saving = false;
        this.openSnackBar('Location saved');
        this.locationService.getLocations(10, 0);
        this.locationService.getLocationCount();
        this.locationForm.markAsPristine();
        this.closeLocation.emit();
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.saving = false;
      });
    }else{
      this.closeLocation.emit();
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  deleteLocation(){
    this.saving = true;
    this.locationService.deleteLocation(this.location.id).then(res => {
      this.saving = false;
      this.openSnackBar('Location deleted');
      this.locationService.getLocations(10, 0);
      this.locationService.getLocationCount();
      this.closeLocation.emit();
    }).catch(err => {
      this.openSnackBar(err);
    })
  }
}
