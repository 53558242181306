import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { faAngry, faCheck, faCheckCircle, faCheckSquare, faClock, faNotesMedical, faPlus, faSadTear, faSmile, faSmileBeam, faSquareFull, faTimes, faTimesCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DailyReport, Student } from 'backend/interfaces/student.interface';
import { StudentService } from 'src/app/admin/services/student.service';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit {
  loading = false;
  student: Student;
  faPlus = faPlus;
  faTrash = faTrash;
  faNeutral = faAngry;
  faSad = faSadTear;
  faHappy = faSmile;
  faTrue = faCheckCircle;
  faFalse = faTimesCircle;
  faTime = faClock;
  faMed = faNotesMedical;
  date: Date;
  diaperChangesArray = [];
  medicationArray = [];
  reportId;
  isAdmin = false;
  dailyReport: DailyReport;
  dailyReportForm: FormGroup;
  
  constructor(private fb: FormBuilder, private studentService: StudentService, private authService: AuthService, private _snackBar: MatSnackBar) {
    this.authService.$User.subscribe((res) => {
      this.isAdmin = res?.get('role') === 'admin';
    })
  }

  async ngOnInit() {
      this.date = new Date();
    this.loading = true;
    this.dailyReport = null;
    this.studentService.activatedStudent$.subscribe(async s => {
      return this.student = s;
    });
    await this.studentService.getStudentDailyReport(this.student.id, this.date.toDateString(), this.date.toDateString()).then(res => {
      this.reportId = res.ReportId;
      this.dailyReport = res;
      this.diaperChangesArray = res.DiaperChanges;
      this.medicationArray = res.Medication;
      this.dailyReportForm = this.fb.group({
        eatBreakfast: this.fb.group({
          eat: [res.EatBreakfast ? res.EatBreakfast.eat : ''],
          note: [res.EatBreakfast ? res.EatBreakfast.note : '']
        }),
        eatSnack1: this.fb.group({
          eat: [res.EatSnack1 ? res.EatSnack1.eat : ''],
          note: [res.EatSnack1 ? res.EatSnack1.note : '']
        }),
        eatLunch: this.fb.group({
          eat: [res.EatLunch ? res.EatLunch.eat : ''],
          note: [res.EatLunch ? res.EatLunch.note : '']
        }),
        eatSnack2: this.fb.group({
          eat: [res.EatSnack2 ? res.EatSnack2.eat : ''],
          note: [res.EatSnack2 ? res.EatSnack2.note : '']
        }),
        drinkBottle1: this.fb.group({
          drink: [res.DrinkBottle1 ? res.DrinkBottle1.drink : ''],
          qty: [res.DrinkBottle1 ? res.DrinkBottle1.qty : ''],
          time: [res.DrinkBottle1 ? res.DrinkBottle1.time : '']
        }),
        drinkBottle2: this.fb.group({
          drink: [res.DrinkBottle2 ? res.DrinkBottle2.drink : ''],
          qty: [res.DrinkBottle2 ? res.DrinkBottle2.qty : ''],
          time: [res.DrinkBottle2 ? res.DrinkBottle2.time : '']
        }),
        drinkBottle3: this.fb.group({
          drink: [res.DrinkBottle3 ? res.DrinkBottle3.drink : ''],
          qty: [res.DrinkBottle3 ? res.DrinkBottle3.qty : ''],
          time: [res.DrinkBottle3 ? res.DrinkBottle3.time : '']
        }),
        sleep1: this.fb.group({
          sleep: [res.Sleep1 ? res.Sleep1.sleep : ''],
          from: [res.Sleep1 ? res.Sleep1.from : ''],
          to: [res.Sleep1 ? res.Sleep1.to : '']
        }),
        sleep2: this.fb.group({
          sleep: [res.Sleep2 ? res.Sleep2.sleep : ''],
          from: [res.Sleep2 ? res.Sleep2.from : ''],
          to: [res.Sleep2 ? res.Sleep2.to : '']
        }),
        sleep3: this.fb.group({
          sleep: [res.Sleep3 ? res.Sleep3.sleep : ''],
          from: [res.Sleep3 ? res.Sleep3.from : ''],
          to: [res.Sleep3 ? res.Sleep3.to : '']
        }),
        diaperChanges: this.diaperChangesArray ? this.fb.array(this.diaperChangesArray?.map(r => this.fb.group(r))) : this.fb.array([]),
        medication: this.medicationArray ? this.fb.array(this.medicationArray?.map(r => this.fb.group(r))) : this.fb.array([]),
        mood: [res.Mood ? res.Mood : ''],
        notes: [res.Notes ? res.Notes : '']
      })
    })
    this.loading = false
  }

  saveReport(){
    this.studentService.saveDailyReport({
      ReportId: this.reportId,
      Date: this.date.toString(),
      StudentID: this.student.id,
      Notes: this.dailyReportForm.controls['notes'].value,
      EatBreakfast: this.dailyReportForm.controls['eatBreakfast'].value,
      EatSnack1: this.dailyReportForm.controls['eatSnack1'].value,
      EatLunch: this.dailyReportForm.controls['eatLunch'].value,
      EatSnack2: this.dailyReportForm.controls['eatSnack2'].value,
      DrinkBottle1: this.dailyReportForm.controls['drinkBottle1'].value,
      DrinkBottle2: this.dailyReportForm.controls['drinkBottle2'].value,
      DrinkBottle3: this.dailyReportForm.controls['drinkBottle3'].value,
      Sleep1: this.dailyReportForm.controls['sleep1'].value,
      Sleep2: this.dailyReportForm.controls['sleep2'].value,
      Sleep3: this.dailyReportForm.controls['sleep3'].value,
      Mood: this.dailyReportForm.controls['mood'].value,
      DiaperChanges: this.dailyReportForm.controls['diaperChanges'].value,
      Medication: this.dailyReportForm.controls['medication'].value
    }).then(res => {
      this.patchReportForm(res);
      this.openSnackBar('Report Saved!');
    });
  }

  get diaperChanges() {
    return this.dailyReportForm.controls["diaperChanges"] as FormArray;
  }

  get medication() {
    return this.dailyReportForm.controls["medication"] as FormArray;
  }

  addDiaperChange() {
    const diaperChangeForm = this.fb.group({
        time: ['', Validators.required],
        diaper: ['', Validators.required],
    });
  
    this.diaperChanges.push(diaperChangeForm);
  }

  removeDiaperChange(diaperIndex: number) {
    this.diaperChanges.removeAt(diaperIndex);
  }

  addMedication() {
    const medicationForm = this.fb.group({
        time: ['', Validators.required],  
        qty: ['', Validators.required],
        medication: ['', Validators.required],
    });
  
    this.medication.push(medicationForm);
  }

  removeMedication(medIndex: number) {
    this.medication.removeAt(medIndex);
  }

  setMood(mood: string){
    this.dailyReportForm.patchValue({mood});
  }

  async patchReportForm(res: DailyReport){
    this.loading = true;
    this.dailyReportForm = null;
    this.dailyReportForm = this.fb.group({
      eatBreakfast: this.fb.group({
        eat: [res.EatBreakfast ? res.EatBreakfast.eat : ''],
        note: [res.EatBreakfast ? res.EatBreakfast.note : '']
      }),
      eatSnack1: this.fb.group({
        eat: [res.EatSnack1 ? res.EatSnack1.eat : ''],
        note: [res.EatSnack1 ? res.EatSnack1.note : '']
      }),
      eatLunch: this.fb.group({
        eat: [res.EatLunch ? res.EatLunch.eat : ''],
        note: [res.EatLunch ? res.EatLunch.note : '']
      }),
      eatSnack2: this.fb.group({
        eat: [res.EatSnack2 ? res.EatSnack2.eat : ''],
        note: [res.EatSnack2 ? res.EatSnack2.note : '']
      }),
      drinkBottle1: this.fb.group({
        drink: [res.DrinkBottle1 ? res.DrinkBottle1.drink : ''],
        qty: [res.DrinkBottle1 ? res.DrinkBottle1.qty : ''],
        time: [res.DrinkBottle1 ? res.DrinkBottle1.time : '']
      }),
      drinkBottle2: this.fb.group({
        drink: [res.DrinkBottle2 ? res.DrinkBottle2.drink : ''],
        qty: [res.DrinkBottle2 ? res.DrinkBottle2.qty : ''],
        time: [res.DrinkBottle2 ? res.DrinkBottle2.time : '']
      }),
      drinkBottle3: this.fb.group({
        drink: [res.DrinkBottle3 ? res.DrinkBottle3.drink : ''],
        qty: [res.DrinkBottle3 ? res.DrinkBottle3.qty : ''],
        time: [res.DrinkBottle3 ? res.DrinkBottle3.time : '']
      }),
      sleep1: this.fb.group({
        sleep: [res.Sleep1 ? res.Sleep1.sleep : ''],
        from: [res.Sleep1 ? res.Sleep1.from : ''],
        to: [res.Sleep1 ? res.Sleep1.to : '']
      }),
      sleep2: this.fb.group({
        sleep: [res.Sleep2 ? res.Sleep2.sleep : ''],
        from: [res.Sleep2 ? res.Sleep2.from : ''],
        to: [res.Sleep2 ? res.Sleep2.to : '']
      }),
      sleep3: this.fb.group({
        sleep: [res.Sleep3 ? res.Sleep3.sleep : ''],
        from: [res.Sleep3 ? res.Sleep3.from : ''],
        to: [res.Sleep3 ? res.Sleep3.to : '']
      }),
      diaperChanges: res.DiaperChanges ? this.fb.array(res.DiaperChanges?.map(r => this.fb.group(r))) : this.fb.array([]),
      medication: res.Medication ? this.fb.array(res.Medication?.map(r => this.fb.group(r))) : this.fb.array([]),
      mood: [res.Mood ? res.Mood : ''],
      notes: [res.Notes ? res.Notes : '']
    });
    this.loading = false;
}

async selectDate(event: any) {
  this.loading = true;
    this.date = event.value;
    await this.studentService.getStudentDailyReport(this.student.ID, this.date.toDateString(), this.date.toDateString()).then(res => {
      if(res.ReportId){
        this.reportId = res.ReportId;
        this.dailyReport = res;
        this.patchReportForm(res);
      }else{
        this.dailyReportForm.reset();
      }
    })
    this.loading = false;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }
}