
<div #cameraContainer class="webcam">

<webcam (click)="detectFaces();" *ngIf="showWebcam" #cam [height]="500" [width]="600" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
[allowCameraSwitch]="allowCameraSwitch" 
[switchCamera]="nextWebcamObservable"
[videoOptions]="videoOptions"
[imageQuality]="1"
(cameraSwitched)="cameraWasSwitched($event)"
(initError)="handleInitError($event)"
></webcam>
<br>

<canvas #cancancanvas class="canvas"></canvas>
<div (click)="detectFaces();" *ngIf="!showSnapshotAnimation" class="link animation-container bg-50perc-black d-flex flex-column align-items-center">
    <h3 class="text-white" style="margin: auto">Identifiseer gesig</h3>
</div>
<div *ngIf="showSnapshotAnimation" class="animation-container">
    <div class="snap-animation green-scanner"></div>
</div>
<!-- <img class="tap-icon" src="./../../../assets/icons8-tap-gesture.gif"/> -->
<!-- <canvas #canvasRef></canvas> -->
<!-- <button class="actionBtn" (click)="triggerSnapshot();">Take A Snapshot</button> -->
<!-- <button class="actionBtn" (click)="toggleWebcam();">Toggle Webcam</button> -->
<br>
<!-- <button class="actionBtn" (click)="showNextWebcam(true);" [disabled]="!multipleWebcamsAvailable">Next Webcam</button>
<input id="cameraSwitchCheckbox" type="checkbox" [(ngModel)]="allowCameraSwitch"><label for="cameraSwitchCheckbox">Allow Camera Switch</label> -->
<!-- <br> -->
<!-- DeviceId: <input id="deviceId" type="text" [(ngModel)]="deviceId" style="width: 500px"> -->
<!-- <button (click)="showNextWebcam(deviceId);">Activate</button> -->
<!-- <br> -->
<!-- Preferred facing mode:
<input type="radio" value="user" name="facingMode" [(ngModel)]="facingMode"> User
<input type="radio" value="environment" name="facingMode" [(ngModel)]="facingMode"> Environment
<input type="radio" value="" name="facingMode" [(ngModel)]="facingMode"> No preference -->

<!-- <h4 *ngIf="messages.length > 0">Messages:</h4> -->
<!-- <ul *ngFor="let msg of messages">
<li>{{msg | json}}</li>
</ul> -->
</div>