import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent implements OnInit {
  loading = false;
  contactForm: UntypedFormGroup;
  constructor(private fb: FormBuilder) { }
  ngOnInit(): void {
      this.contactForm = this.fb.group(
        {
          email: ['']
      });
  }
  submit(): void{
    this.loading=true;
    setTimeout(() => {
      this.loading=false;    
    }, 1000);
  
  }

}
