import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LocationService } from 'src/app/admin/services/location.service';
import { StudentService } from 'src/app/admin/services/student.service';
import { ServicesService } from 'src/app/admin/services/services.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';


@Component({
  selector: 'app-search-service',
  templateUrl: './search-service.component.html',
  styleUrls: ['./search-service.component.scss']
})
export class SearchServiceComponent implements OnInit {

  loading = false;
  searchServiceForm: UntypedFormGroup;
  noResults = false;
  constructor(private fb: UntypedFormBuilder, private servicesService: ServicesService, private sideNavService: SideNavService) { }

  ngOnInit(): void {
      this.searchServiceForm = this.fb.group(
        {
          title: [''],
          duration: [''],
          price: ['']
      });
      console.log(this.searchServiceForm)
  }
  search(){
    this.noResults = false;
    this.loading = true;
    this.servicesService.searchService(this.searchServiceForm.value).then(res => {
      if(res.length > 0){
        this.servicesService.services.next(res);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }
}
