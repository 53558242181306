
<mat-progress-bar
    [mode]="loading ? 'indeterminate' : 'determinate'"
    [value]="0">
</mat-progress-bar>
<form *ngIf="searchMealPlanForm" [formGroup]="searchMealPlanForm" (submit)="search()">
    <div class="d-flex row p-3">
    <div class="col-md-12 d-flex flex-column">
        <mat-form-field appearance="outline">
            <mat-label>Title</mat-label>
            <input matInput placeholder="title" name="title" formControlName="title">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Group</mat-label>
            <input matInput placeholder="group" name="group" formControlName="group">
        </mat-form-field>
    </div>
    </div>
    <div mat-toolbar class="d-flex justify-content-between p-3">
        {{noResults ? 'No Results Found' : ''}}
        <div class="spacer"></div>
        <button mat-raised-button color="accent" [disabled]="loading" type="submit" class="btn btn-outline-secondary">
            {{ loading ? 'Searching' : 'Search' }}
        </button>
    </div>
</form>
