import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { BehaviorSubject } from 'rxjs';
import { CalendarEvent } from 'angular-calendar';
import { GoogleCalendarEvent } from 'backend/interfaces/calendar.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MedicalDetails, Student } from 'backend/interfaces/student.interface';
import * as moment from "moment";
import { Member, MemberConsent, MemberSearchCriteria } from 'backend/interfaces/member.interface';
@Injectable({
  providedIn: 'root'
})

export class ParentService {
  parents = new BehaviorSubject<Member[] | null>(null);
  parents$ = this.parents.asObservable();
  parentSearch = new BehaviorSubject<{name:string, surname: string, mobile: string, email: string} | null>(null);
  parentsSearch$ = this.parentSearch.asObservable();
  parentBirthdays = new BehaviorSubject<any[] | null>(null);
  activatedParent = new BehaviorSubject<Member>(null);
  activatedParent$ = this.activatedParent.asObservable();
  activatedDrive = new BehaviorSubject<any[]>(null);
  activatedDrive$ = this.activatedDrive.asObservable();
  parentBirthdays$ = this.parentBirthdays.asObservable();
  parentCount = new BehaviorSubject<number | null>(null);
  parentCount$ = this.parentCount.asObservable();
  uploadToken: string;
  public newParentEmail: string;
  constructor(private http: HttpClient) {
  }

  saveParent(parent: Member): Promise<Member> {
    return Parse.Cloud.run('saveParent', { parent }).then((result) => {
      return result;
    });
    
  }

  saveParents(parents: Member[]): Promise<any>{
    return Parse.Cloud.run('saveParents', { parents }).then((result) => {
      return result;
    });
  }

  saveParentConsent(parentConsent: MemberConsent): Promise<Member> {
    return Parse.Cloud.run('saveParentConsent', { parentConsent }).then((result) => {
      return result;
    });
  }

  saveParentMedicalDetails(medicalDetails: MedicalDetails): Promise<Member> {
    return Parse.Cloud.run('saveParentMedicalDetails', { medicalDetails }).then((result) => {
      return result;
    });
  }

  getParents(displayLimit: number, page: number): Promise<void> {
    return Parse.Cloud.run('getParents', { displayLimit, page }).then((result) => {
      this.parents.next(result);
    });
  }

  getMemberById(id: string): Promise<Member> {    
    return Parse.Cloud.run('getMemberById', { id }).then(res => this.activatedParent.next(res));
  }

  getParentById(id: string): Promise<Member> {    
    return Parse.Cloud.run('getMemberById', { id });
  }

  getStudentsByParentId(id: string, mainContact: boolean): Promise<Student[]> {
    return Parse.Cloud.run('getStudentsByParentId', { id, mainContact });
  }

  getMemberByEmail(email: string): Promise<Member> {
    return Parse.Cloud.run('getMemberByEmail', { email });
  }

  getCalendarBusy(): Promise<any>{
    const from = "2023-01-01T00:00:00.000Z";
    const to = "2023-04-01T00:00:00.000Z";
    return Parse.Cloud.run('getCalendarBusy', { from, to });
  }

  getCalendarEvents(): Promise<any>{
    return Parse.Cloud.run('getCalendarEvents');
  }

  getParentCount(): Promise<number>{
    return Parse.Cloud.run('getParentCount').then((result) => {
      this.parentCount.next(result);
    });;
  }

  getParentBirthdays(date: string): Promise<any> {
    return Parse.Cloud.run('getParentBirthdays', { date }).then((result) => {

      const date = moment(new Date());
      let res = result.filter(r => {
        return moment(r.DOB).date() > date.date();
      });
      this.parentBirthdays.next(res);
    });;
  }

  createBooking(event: GoogleCalendarEvent): Promise<any>{
    return Parse.Cloud.run('createBooking', { event });
  }

  updateBooking(event: GoogleCalendarEvent): Promise<any>{
    return Parse.Cloud.run('updateBooking', { event });
  }

  deleteBooking(id: string): Promise<any>{
    return Parse.Cloud.run('deleteBooking', { id });
  }

  searchParent(criteria: MemberSearchCriteria): Promise<any>{
    return Parse.Cloud.run('searchParent', { criteria });
  }

  saveProfilePicture(parentId: string, image: any): Promise<any>{
    return Parse.Cloud.run('saveProfilePicture', { parentId, image });
  }

  uploadPictureToBucket(uploadedFile: any, name: string, bucket: string, id?: string): Promise<any>{
    return Parse.Cloud.run('uploadFileToBucket', { uploadedFile, name, bucket, id })
  }

  recogniseFace(uploadedFile: any, name: string, bucket: string): Promise<any>{
    const reqHeaders = new HttpHeaders({
      'Content-Type': uploadedFile.type,
      "Access-Control-Allow-Origin": "*"
    })
      return this.http.put(`https://ng6nmwy4yc.execute-api.eu-west-1.amazonaws.com/dev/${bucket}/${uploadedFile.name}`, uploadedFile, {headers: reqHeaders}).toPromise().then(async () => {
          const response = await this.authenticate(uploadedFile);
          if(response.Message === 'Success'){
              return `Hi ${response['firstName']} ${response['firstName']}`
          }else{
              return `Not found`
          }
      }).catch(err => {
          return `Error: ${err}`
      })
  }

  async authenticate(file: any): Promise<any> {
    const reqHeaders = new HttpHeaders({
      'Content-Type': file.type
    })
    const requestUrl = 'https://ng6nmwy4yc.execute-api.eu-west-1.amazonaws.com/dev/person?'+ new URLSearchParams({
        objectKey: file.name
    })
    return await this.http.get(requestUrl, {
        headers: reqHeaders
    }).toPromise().then(response => response)
    .then(data => {
        return data;
    }).catch(err => {
      console.log(err)
      return err.error.Message;
    });
    }

  getParentFile(id: string, mimeType: string): Promise<any>{
    return Parse.Cloud.run('getFileById', { id, mimeType })
  }

  getparentFiles(fileId: string): Promise<any>{
    return Parse.Cloud.run('getFilesById', { fileId })
  }

  getAlbumList(id: string): Promise<any>{
    return Parse.Cloud.run('getAlbumList', { id })
  }

  getAlbumById(id: string): Promise<any>{
    return Parse.Cloud.run('getAlbumById', { id })
  }

  createDriveFolder(parentId: string): Promise<any>{
    return Parse.Cloud.run('createParentDriveFolder', { parentId })
  }

  createAccount(): Promise<any>{
    return Parse.Cloud.run('saveAccount')
  }

  createPhotoAlbum(parentId: string): Promise<any>{
    return Parse.Cloud.run('createPhotoAlbum', { parentId })
  }

  uploadFile(id: string, uploadedFile: any, description: string): Promise<any>{
    return Parse.Cloud.run('uploadFile', { id, uploadedFile, description })
  }

  async uploadPhoto(id: string, uploadedFile: any, description: string): Promise<any>{
    const authToken = await Parse.Cloud.run('getGToken', { });
    let body = uploadedFile.source;
    let newMediaItems = [];

    const reqHeaders = new HttpHeaders({
        Authorization: 'Bearer '+ authToken,
        'Content-type': 'application/octet-stream',
        'X-Goog-Upload-Content-Type': uploadedFile.type,
        'X-Goog-Upload-File-Name': uploadedFile.name,
        'X-Goog-Upload-Protocol': 'raw'
      })
    

      const batchCreateHeaders = new HttpHeaders({
        Authorization: 'Bearer '+ authToken,
        'Content-type': 'application/json'
      })
    
    let uploadToken = await this.http.post(
      'https://photoslibrary.googleapis.com/v1/uploads',
      body, {headers: reqHeaders, responseType: 'text'}).toPromise(); 

      // uploadToken.tap(res => {
    newMediaItems.push(
          {
            description: uploadedFile.description,
            simpleMediaItem: {
              fileName: uploadedFile.name,
              uploadToken: uploadToken
            }
          });

      return this.http.post(
            'https://photoslibrary.googleapis.com/v1/mediaItems:batchCreate',
            { albumId: id, newMediaItems }, {headers: batchCreateHeaders}).toPromise();

    // return Parse.Cloud.run('uploadPhoto', { id, uploadedFile })

  }
}

