
<mat-progress-bar
    [mode]="loading ? 'indeterminate' : 'determinate'"
    [value]="0">
</mat-progress-bar>
<form [formGroup]="contactForm" (submit)="submit()">
    <div class="d-flex row p-3">
    <div class="col-md-12 d-flex flex-column">
        <mat-form-field appearance="outline">
            <mat-label>Epos addres</mat-label>
            <input matInput placeholder="epos address" name="email" formControlName="email">
        </mat-form-field>
    </div>
    </div>
    <div mat-toolbar class="d-flex justify-content-between p-3">
        <div class="spacer"></div>
        <button mat-raised-button color="primary" [disabled]="loading" type="submit" class="btn-block btn btn-outline-secondary text-white">
            {{ loading ? '...' : 'Teken op' }}
        </button>
    </div>
</form>
