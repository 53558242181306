import { Component, Input, OnInit } from '@angular/core';
import { PDFDocument } from 'pdf-lib';
import FileSaver from 'file-saver';
import { faPlus, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pdf-worker',
  templateUrl: './pdf-worker.component.html',
  styleUrls: ['./pdf-worker.component.scss']
})
export class PdfWorkerComponent implements OnInit {
  documents = [];
  faUpload = faUpload;
  faTrash = faTrash;
  faPlus = faPlus;
  documentsForm: UntypedFormGroup;
  constructor(private router: Router, private fb: UntypedFormBuilder) {}
  

onFileSelected(event, index) {
  const file:File = event.target.files[0];
  if (file) {
    this.documentsFieldAsFormArray.controls[index].patchValue({fileName: file.name});
      this.documents.push({name: file.name, doc: file.arrayBuffer()});
  }
}

  ngOnInit(): void {
    this.documentsForm = this.fb.group({
      documents: this.fb.array([])
    });
    
  }

  get documentsFieldAsFormArray() {
    return this.documentsForm.get('documents') as UntypedFormArray;
  }

  document(): any {
    return this.fb.group({
      document: [],
      fileName: ''
    });
  }

  addControl(): void {
    this.documentsFieldAsFormArray.push(this.document());
  }

  remove(i: number): void {
    this.documentsFieldAsFormArray.removeAt(i);
    this.documents.splice(i, 1);
  }

  async embedPdfPages(): Promise<void> {
    const mergedPdf = await PDFDocument.create();
    for(let element of this.documents) {
      console.log(element);
      const temp = await element.doc.then(res => res);
      let document = await PDFDocument.load(temp);
      const copiedPages = await mergedPdf.copyPages(document, document.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    };

    const pdfBytes = await mergedPdf.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    FileSaver.saveAs(blob, 'mergedResult')
  }

  uploadFile(id: number){
    const fileId = "file"+id;
    document.getElementById(fileId).click();
  }


  continueToSignIn(){
    this.router.navigateByUrl('auth');
  }

  goHome(){
    this.router.navigateByUrl('');
  }


}
