import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MealPlanService } from 'src/app/admin/services/meal-plan.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';


@Component({
  selector: 'app-search-meal-plan',
  templateUrl: './search-meal-plan.component.html',
  styleUrls: ['./search-meal-plan.component.scss']
})
export class SearchMealPlanComponent implements OnInit {

  loading = false;
  searchMealPlanForm: UntypedFormGroup;
  noResults = false;
  constructor(private fb: UntypedFormBuilder, private mealPlanService: MealPlanService, private sideNavService: SideNavService) { }

  ngOnInit(): void {
      this.searchMealPlanForm = this.fb.group(
        {
          title: [''],
          group: ['']
      });
  }
  search(){
    this.noResults = false;
    this.loading = true;
    this.mealPlanService.searchMealPlan(this.searchMealPlanForm.value).then(res => {
      if(res.length > 0){
        this.mealPlanService.mealPlans.next(res);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }
}
