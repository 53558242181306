
<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
    <div>
      <mat-spinner color="accent"></mat-spinner>
    </div>
  </div>
<form *ngIf="!loading" class="user mb-5" [formGroup]="mealForm" (ngSubmit)="saveMeal()">
    <div class="mb-3 mt-3">
      <div class="row m-0 mb-3">
          <div class="col-md-5 photo-container border-radius-5 link" (click)="uploadFile()">
            <fa-icon class="upload-icon text-secondary" [icon]="faUpload" size="2x"></fa-icon>
            <img class="text-center margin-auto link" [src]="photo" #imagePreview alt="image">
            <input
            type="file"
            (change)="onFileSelected($event)"
            #fileInput
            formControlName="photo"
            style="display: none;"
          />
          </div>          
          <div class="col-md-7 d-flex align-items-center">
            <mat-form-field class="flex-1 w-100 mat-display-1">
              <mat-label>Title</mat-label>
              <input matInput formControlName="title" placeholder="title" />
          </mat-form-field>
          </div>

      </div>
    <div class="border-primary p-2 mb-3">
        <ng-container formArrayName="ingredients">
            <h3>Ingredients</h3>
            <ng-container
            *ngFor="let ingredientForm of ingredients?.controls; let i = index"
            >
            <div
                class="meal-form-row d-flex justify-content-between align-items-center"
                [formGroup]="ingredientForm"
            >
                <mat-form-field class="mr-2 flex-1">
                <mat-label>Title</mat-label>
                <input matInput formControlName="title" placeholder="title" />
                </mat-form-field>

                <fa-icon
                class="ml-3 mr-3 link"
                [icon]="faTrash"
                (click)="removeIngredient(i)"
                ></fa-icon>
            </div>
            </ng-container>
        </ng-container>
        <button mat-mini-fab type="button" (click)="addIngredient()">
            <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        </div>
        <div class="border-primary p-2">
          <h3>Instructions</h3>

            <mat-form-field class="w-100" appearance="fill">
              <mat-label>Instructions</mat-label>
              <textarea rows="5" matInput placeholder="" formControlName="instructions"></textarea>
            </mat-form-field>

        </div>
    </div>

  <mat-toolbar class="d-flex justify-content-between">
    <button *ngIf="meal.id" type="button" (click)="deleteMeal()" mat-stroked-button color="accent">Delete</button>
    <button type="submit" mat-raised-button color="primary">Save</button>
  </mat-toolbar>
</form>
