import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPaperPlane, faUser } from '@fortawesome/free-solid-svg-icons';
import { ParseClasses } from 'backend/enum/ParseClasses.enum';
import { ParsePointer } from 'backend/interfaces/ParsePointer';
import * as Parse from "parse";
import { ParsedUrlQuery } from 'querystring';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit, OnDestroy {
  @Input('recipient') recipient: Parse.User;

  faUser = faUser;
  MessageObj = Parse.Object.extend('Messages');
  messages;
  loading = false;
  sending = false;
  subscription;
  faPaperPlane = faPaperPlane;
  query = new Parse.Query(ParseClasses.MESSAGES);
  currentUser = Parse.User.current().get('Name');
  senderQuery1 = new Parse.Query(ParseClasses.MESSAGES);
  recipientQuery1 = new Parse.Query(ParseClasses.MESSAGES);
  senderQuery2 = new Parse.Query(ParseClasses.MESSAGES);
  recipientQuery2 = new Parse.Query(ParseClasses.MESSAGES);
  conversationId = '';
  combinedQuery = Parse.Query.or(Parse.Query.and(this.senderQuery1, this.recipientQuery1), Parse.Query.and(this.senderQuery2, this.recipientQuery2));
  // combinedQuery = new Parse.Query(ParseClasses.MESSAGES);
  myInterval;
  messageForm: FormGroup = this.fb.group({
    message: ['']
  })
  @ViewChild('messageInput') messageInput: ElementRef;
  @Output() emitClose = new EventEmitter<Boolean>();
  constructor(private chatService: ChatService, private authService: AuthService, private dataService: DataService, private fb: FormBuilder) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.senderQuery1.equalTo('Sender', Parse.User.current());
    this.recipientQuery1.equalTo('Recipient', this.recipient);
    this.senderQuery2.equalTo('Sender', this.recipient);
    this.recipientQuery2.equalTo('Recipient', Parse.User.current());
    this.combinedQuery.first().then(r => {
      console.log(r)
      this.conversationId = r? r.get('conversation') : '';
      console.log(this.conversationId);
      if(this.conversationId !== ''){
        this.subscribeToLiveQuery();
      }
    }).catch(err => console.log(err))
  }

  ngAfterViewInit(): void {
    this.messageInput?.nativeElement.focus();
    // this.myInterval = setInterval(() => {
    //   this.refresh();
    // }, 10000);
  }

  ngOnDestroy(): void {
    console.log('destroy')
    clearInterval(this.myInterval);
  }
  createChat(){
  //   console.log(this.chatId)
  //   this.chatService.createChatSpace(this.user).then(res => {
  //     console.log(res);
  //   })
  }


  async subscribeToLiveQuery(){
    this.query.equalTo('conversation', this.conversationId);
    this.query.ascending('createdAt');  
    this.query.limit(100);
    await this.query.find().then(messages => {
      this.messages = new Set(messages);
      this.loading = false;
    }).catch(error => {
      alert(`Failed to retrieving objects, with error code: ${error.message}`);
    });
    this.subscription = await this.query.subscribe();
    this.subscription.on('open', message => {
      console.log('trigger open')

      this.messages.forEach(t => {
        if(Parse.User.current().id === t.get('Recipient').id && t.get('read')===false){
          console.log(t)
          t.set('read', true);
          t.save();
        }
      });
    });
    this.subscription.on('create', message => {
      this.messages.forEach(t => {
        if(Parse.User.current().id === t.get('Recipient').id && t.get('read')===false){
          console.log(t)
          t.set('read', true);
          t.save();
        }
      });
      this.messages.add(message);
      console.log('trigger create')
    });
    this.subscription.on('update', message => {
      console.log(message)
    });
    this.subscription.on('leave', message => {
      console.log('trigger leave')
    });
    this.subscription.on('delete', message => {
      this.messages.forEach(t => {
        if (t.id === message.id) {
          this.messages.delete(t);
        }
      });
    });
  }
refresh(){
  this.subscribeToLiveQuery();
}
  send(){
    this.sending = true;
    this.chatService.sendMessage(this.messageForm.controls['message'].value, this.recipient.id, this.conversationId).then(res => {
      // this.refresh();
      this.messageForm.controls['message'].setValue('');
      this.messageForm.reset();
      this.messageForm.markAsPristine();
      this.sending = false;
      // this.subscribeToLiveQuery();
    }).catch(err => console.log(err));
    // if (this.message) {
    //   const msg = new this.MessageObj();
    //   msg.save({Sender: Parse.User.current(), Message: this.message});
    // }
    // this.message = null;
  }
startConversation(){
  this.conversationId = Parse.User.current().id + '-' + this.recipient.id;
  this.subscribeToLiveQuery();
}
}
